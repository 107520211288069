<template lang="pug">
nav.nav-bottom(@click.stop='$root.retract_user = true')
  .logo(@click="$router.push('/')")
    img(:src="!$root.retract && /laptop/.test($root.size) ? ($root.cssvar.nav || '').slice(4, -1) : ($root.cssvar.icon || '').slice(4, -1)")
    .title {{ t.title }}
  .account
    .userflow(v-if="$root.app && $root.app.includes('natixis') && $root.userflow.name") {{ t[$root.userflow.name] || ($root.userflow.name) }}
    div(v-if="$root.db && $root.db.env")  {{ $root.db.env }}
    div(v-if="$root.userflow && $root.userflow.shares")
      | {{ t.share }} {{ ($root.userflow.shares[$root.params.userflow.split('-')[1]] && $root.userflow.shares[$root.params.userflow.split('-')[1]].share) || '' }}
    div(v-if="$root.nav_data && $root.nav_data.path") {{ t.share }} {{ $root.nav_data.path }}
  .links
    template(v-if="$root.app")
      router-link.slash(:to="$root.appath" v-if="hasRoleAuth('slash')")
        svg-icon(:name="$root.config.screens.slash?.icon")
        span {{ t.slash }}
      router-link(:class='[{ exact: $route.params.screen === path }, path]', :to="[path, $root.query.__.map((v, k) => k + '=' + v).__.v().join('&')].__.filter(d => d).join('?')" v-for="path in $root.config.menu?.filter(m => hasRoleAuth(m))")
        svg-icon(:name="$root.config.screens[path].icon")
        span {{ t[path] }}
    template(v-for="screen, i in $root.screens" v-if="$root.screen.path !== 'slash' && !$root.config.menu")
      .menu.column(:key='i', v-if="type(screen) === 'array'", @click='opened = opened === screen[0].path ? null : screen[0].path')
        a.menu-title
          svg-icon(:name='screen[0].icon')
          span {{ t[screen[0].path] }}
        .menu-links.column(:class='{ opened: opened === screen[0].path }')
          router-link(:class='{ selected: $route.params.screen === s.path, [s.path]: true }', :to="s.path === 'slash' ? '/' : { params: { screen: s.path }, query: $route.query || s.query }", v-for='s, i in screen.slice(1)')
            svg-icon(:name='s.icon')
            span {{ t[s.path] }}
      router-link(:class="{ [screen.path]: true, exact: activePath === screen.path }" :to="{path: screen.path, query: $route.query}" v-if="$root && type(screen) === 'object' && (($root.role === 'admin' || ($root.profile && !$root?.profile?.screens)) || (($root.profile && $root?.profile?.screens) || []).includes(screen.path))")
        svg-icon(:name="screen.icon")
        span {{ t[screen.path] }}
  nav-user-menu()
  .retract-button(@click="$root.retract = !$root.retract")
    svg-icon(:name="/laptop/.test($root.size) ? 'nx-chevron' : 'ic_menu'")
</template>

<script>
import { computed, onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
export default {
  setup() {
    const route = useRoute()
    const activePath = computed(() => route?.path.split('/').last())
    const userAuthorizedScreens = ref([])

    const loadUserAuthorizedScreens = async () => {
      if ($root.$policy) {
        userAuthorizedScreens.value = await $root.$policy.findUserRoleAuthorizedScreens()
      } else {
        userAuthorizedScreens.value = $root.$config.apps[route.meta?.app]?.userRoleAuthorizedScreens || []
      }
    }

    const userRoleAuthorizedScreens = computed(() => userAuthorizedScreens.value)

    const hasRoleAuth = path => {
      return (
        $root.profile?.role === 'admin' ||
        !userRoleAuthorizedScreens.value.length ||
        userRoleAuthorizedScreens.value.includes(path)
      )
    }

    onMounted(() => {
      loadUserAuthorizedScreens()
    })

    return {
      route,
      activePath,
      hasRoleAuth,
    }
  },
  data() {
    return {
      opened: '',
    }
  },
  watch: {
    '$root.retract'() {
      window.dispatchEvent(new Event('resize'))
    },
    activePath(value) {
      if (!value) {
        $root.nav_data.path = ''
        $root.nav_data.subpath = ''
      }
    },
  },
  computed: {
    data_quality_badge() {
      if (!$root.db['data-quality']) return false
      if (!$root.profile || $root?.profile?.role !== 'admin') return false
      return this.checks.__.map(d => d.error_number + d.warning_number).sum()
    },
    checks() {
      const ignore_list = $root.db['data-quality-ignored'] || {}
      const is_ignored = message => !!ignore_list[message.md5()]
      return $root.db['data-quality'].__.map(d => d.__.v())
        .__.v()
        .flat(Infinity)
        .group('name')
        .__.v()
        .__.map(grp => {
          const messages = grp.__.filter(d => d.error_output || d.warning_output).__.map(d => {
            const message = (d.error_output || d.warning_output).join('\n')
            return {
              type: d.error_output ? 'error' : 'warning',
              message,
              ignore: is_ignored(message),
            }
          })
          const error_number = messages.__.filter(d => d.type === 'error' && !d.ignore).__.v().length
          const warning_number = messages.__.filter(d => d.type === 'warning' && !d.ignore).__.v().length
          return {
            name: grp[0].name,
            label: grp[0].description,
            category: grp[0].category,
            messages,
            error_number,
            warning_number,
            result: error_number + warning_number === 0 ? 0 : error_number === 0 ? 1 : 2,
          }
        })
    },
  },
  mounted() {
    this.$root.retract_user = true
  },
}
</script>
