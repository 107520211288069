import { external } from 'jszip'
import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useTransactions() {
  async function getTransactions({ isinShare, domain, asof, externalOptions }) {
    const { sendQuery } = useGraphQL(config)
    const query = `#graphql
      query Transactions($fuid: FuidInput!, $domain: Domain!, $externalOptions: JSON) {
        transactions {
          all(fuid: $fuid, domain: $domain, externalOptions: $externalOptions) {
            fuid
            name
            type
            value
            variation
          }
        }
      }`
    const variables = {
      fuid: isinShare,
      domain,
      externalOptions,
    }
    const resp = await sendQuery(query, variables)
    return resp.transactions.all
  }

  return {
    getTransactions,
  }
}
