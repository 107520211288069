<script setup>
import { ref, onMounted, onUnmounted, computed, reactive, watch } from 'vue'
import { useAllocation } from '../../composables/useAllocation.js'
import { useAutocomplete } from '../../composables/useAutocomplete.js'
import { useXfilter } from '../../composables/useXfilter.js'
import { useDates } from '../../composables/useDates'

const { getAllocationData } = useAllocation()
const { initAutocomplete, autocompleteAvailableData, activeAutocompleteFilters, onFiltersUpdateAutoComplete } =
  useAutocomplete()
const { initXfilter, filteredDataset, datasetByDimensions } = useXfilter()
const { getDatesQuery } = useDates()
const loaded = ref(false)
const availableDimensions = ref()
const asof = ref()
const detail_characteristics = ref()
const state = reactive({ extension: 'csv' })
const fund_asset_class = ref()

const leave = (el, done) => {
  el.style.opacity = 0
  setTimeout(() => done(), 500)
}

const loadData = async () => {
  loaded.value = false

  const domain = $root.$route.query.domain || $root.domain.join('|')
  let params = {
    isinShare: $root.$route.fullPath.split('/')[2].split('-')[1],
    domain: $root.query.evolution ? domain : domain.split('|').last() + '|' + domain.split('|').last(),
    asof: $root.domain[1],
    lang: $root.$route.query.lang || $root.lang,
  }

  if (!$root.dates || $root.dates.isin_share !== params.isinShare || $root.dates.query !== 'allocation') {
    $root.dates = await getDatesQuery(params, 'allocation')
    $root.dates.isin_share = params.isinShare
    $root.dates.query = 'allocation'
    $root.refresh = $root.refresh + 1
    params.domain = $root.domain.join('|')
    params.asof = $root.domain[1]
  }
  const data = await getAllocationData(params)
  fund_asset_class.value = data.share.characteristics?.fund_asset_class || ''
  $root.tab_benchmark = data.share.benchmark
  $root.tab_userflow_name = data.share.slug
  $root.nav_data.path = data.share.characteristics?.share_letter
  $root.refresh = $root.refresh + 1
  asof.value = data.share.asof
  detail_characteristics.value = data.share.allocation[0].axis.keys()
  availableDimensions.value = data.share.allocation[0].axis.keys()
  initAutocomplete(data.share.allocation, ['name', ...availableDimensions.value])
  initXfilter(data.share.allocation, ['name', ...availableDimensions.value])
  $root.bench_weight_nul = filteredDataset.value.map(d => d.weight_benchmark)?.sum() === 0
  // if (filteredDataset.value.map(d => d.weight_benchmark).sum() === 0) {
  //   µ('main').classList.add('no_benchmark_data')
  // }
  loaded.value = true
}

onMounted(loadData)
watch(() => $root.query.domain, loadData)
watch(() => $root.query.evolution, loadData)

onUnmounted(() => ($root.bench_weight_nul = false))

const metric = computed(() =>
  $root.query.metric?.includes('benchmark') ? 'benchmark' : $root.query.metric ? $root.query.metric : 'fund',
)
const columns = computed(() => {
  if (fund_asset_class.value === 'fund_asset_class_actions')
    return ['name', 'weight', 'exposure', 'contrib_deltaaction']
  if (
    fund_asset_class.value === 'fund_asset_class_obligations' ||
    fund_asset_class.value === 'fund_asset_class_monetaire'
  )
    return ['name', 'weight', 'exposure', 'contrib_sensi']
  if (
    fund_asset_class.value === 'fund_asset_class_diversifies' ||
    fund_asset_class.value === 'fund_asset_class_convertibles'
  )
    return ['name', 'weight', 'exposure', 'contrib_sensi', 'contrib_deltaaction']
  return ['name', 'weight', 'exposure', 'contrib_sensi', 'contrib_deltaaction']
})

const mapped_dataset = computed(() => {
  let dataset = filteredDataset.value.map(d => {
    let res = d
    columns.value
      .filter(c => c !== 'name')
      .forEach(column => {
        res[column] =
          metric.value === 'diff' ? d[column + '_fund'] - d[column + '_benchmark'] : d[column + '_' + metric.value]
      })
    return res
  })
  if (metric.value === 'fund') {
    dataset = dataset.filter(d => d.weight_fund && d.weight_fund !== 0)
  }
  if (metric.value === 'benchmark') {
    dataset = dataset.filter(d => d.weight_benchmark && d.weight_benchmark !== 0)
  }
  return dataset
})

const tableclick = (additionnals_cols = [], extension = 'csv') => {
  const flattened_cols = detail_characteristics.value
  const export_cols = columns.value.concat(flattened_cols)
  const final_data = mapped_dataset.value
    .map(d => {
      detail_characteristics.value.map(kpi => (d[kpi] = $root.t[d.axis[kpi]] || d.axis[kpi] || 'NA'))
      return d
    })
    .map(d => d.filter((v, k) => export_cols.includes(k)))
    .sort((a, b) => b.weight - a.weight)

  switch (extension) {
    case 'csv':
      return final_data.dlCSV($root.lang, 'lines.csv')
    case 'xlsx':
      return final_data.dlXLS('lines.xlsx')
  }
}
const changeExt = e => {
  state.extension = e.target.value
}
</script>
<script>
export const additions = { calendar: 'daily', icon: 'ic_storage' }
</script>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="!loaded")
template(v-if="loaded")
  .screen-title
    .row.between
      h1 {{ t['detail'] || 'detail' }}
      autocomplete(:data="autocompleteAvailableData" :options="{ placeholder: t.search }" :modelValue="activeAutocompleteFilters" @update:modelValue="onFiltersUpdateAutoComplete")
    .button-bar
      .evolution
        router-link(:class="{ on: !$route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: null }).filter(d => d) }") {{ t[$root.screen.path] || $root.screen.path }}
        router-link(:class="{ on: $route.query.evolution }" :to="{ query: Object.assign({}, $route.query, { evolution: 'on' }) }") {{ t.evolution }}
  .block
    .row.right.download
      button(@click="tableclick([], state.extension)")
        span {{ t.export_table }}
      select(@change="changeExt")
        option(v-for="exten in ['csv', 'xlsx']" :value="exten") {{ exten }}
    board(:data="mapped_dataset" :metadata="{ expand: 'id', sort: '-weight', desc: true, columns }")  
      
      template(v-slot:header-weight)
        div {{ t.weight }}
        span {{ t.on_last_day }}
      template(v-slot:header-exposure)
        div {{ t.exposure_fund }}
        span {{ t.on_last_day }}
      template(v-slot:header-contrib_sensi v-if="columns.includes('contrib_sensi')")
        div {{ t.contrib_sensi_fund }}
        span {{ t.on_last_day }}
      template(v-slot:header-contrib_deltaaction v-if="columns.includes('contrib_deltaaction')")
        div {{ t.contrib_deltaaction_fund }}
        span {{ t.on_last_day }}

      template(v-slot:cell-name="s")
        div {{ s.line.name }}
        //- span(v-if="metric === 'fund' && $root.tab_benchmark && s.line.weight_benchmark") {{ t[$root.tab_benchmark.id] || ('' + $root.tab_benchmark.id).titleize()  }}
        //- span(v-if="metric === 'benchmark' && s.line.weight_fund") {{ t[$root.tab_userflow_name] || $root.tab_userflow_name }}
      template(v-slot:cell-weight="s")
        div {{metric === 'diff' && s.line.weight>0 ? '+' + format('weight_fund')(s.line.weight) : format('weight_fund')(s.line.weight) }}
        span(v-if="metric === 'fund' && $root.tab_benchmark && s.line.weight_benchmark") {{ format('weight_fund')(s.line.weight_benchmark) }}
        span(v-if="metric === 'benchmark' && s.line.weight_fund") {{ format('weight_fund')(s.line.weight_fund) }}
        span(v-if="metric === 'diff'") {{ format('weight_fund')(s.line.weight_fund) }} - {{ format('weight_fund')(s.line.weight_benchmark) }}
      template(v-slot:cell-exposure="s")
        div {{metric === 'diff' && s.line.exposure > 0 ? '+' + format('exposure_fund')(s.line.exposure) : format('exposure_fund')(s.line.exposure) }}
      template(v-slot:cell-contrib_sensi="s" v-if="columns.includes('contrib_sensi')")
        div {{metric === 'diff' && s.line.contrib_sensi > 0 ? '+' + format('contrib_sensi_fund')(s.line.contrib_sensi) : format('contrib_sensi_fund')(s.line.contrib_sensi) }}
      template(v-slot:cell-contrib_deltaaction="s" v-if="columns.includes('contrib_deltaaction')")
        div {{metric === 'diff' && s.line.contrib_deltaaction > 0 ? '+' + format('contrib_deltaaction_fund')(s.line.contrib_deltaaction) : format('contrib_deltaaction_fund')(s.line.contrib_deltaaction) }}
      
      template(v-slot:expand="s")
        .block.plots-info.column
          .row
            h2.exp_title {{ $root.t.characteristics }}
          .expandable_kpis
            .row.date
              .title {{ $root.t.date }}
              .result {{ s.line.date }}
            .row.isin
              .title ISIN
              .result {{ s.line.isin }}
            .row.asof 
              .title Asof
              .result {{ asof }}
            .row.id
              .title Id
              .result {{ s.line.id }}
            .row(:class="kpi" v-for="kpi in detail_characteristics")
              .title {{ $root.t[kpi] || kpi || 'NA' }}
              .result {{ $root.t[s.line.axis[kpi]] || s.line.axis[kpi] || 'NA' }}
</template>
<style>
/* DETAILS */
.screen-detail.no_benchmark_data .tab {
  display: none;
}
.board .plots-info {
  flex-direction: row;
  align-items: flex-start;
}

.board .plots-info .expandable_kpis {
  column-count: 2;
}
.board .expandable_kpis {
  min-width: 100%;
}
.board .plots-info .expandable_kpis .row {
  min-width: 100%;
}
.board .plots-info .title {
  font-weight: 600;
  color: black;
}
.board .plots-info .result {
  color: black;
}
.board .plots-info .row:nth-child(n + 2) {
  color: black;
}
.board .line:not(.first-line):hover > .tooltip {
  display: none;
}
.board .line:not(.first-line) {
  display: flex !important;
}
</style>
