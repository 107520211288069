<style scoped>
.page {
  justify-content: flex-start;
  place-content: center;
  place-items: center;
}
.page-content {
  max-width: 920px !important;
  width: 100%;
}
h1 {
  font-size: 56px;
}
h3 {
  margin-bottom: 32px !important;
}
.lang-menu {
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>

<template lang="pug">
.page-content
  h1 {{ t.title }}
    //- input(placeholder="Search ...")
  h3 {{ t.subtitle }}
  .column 
    app-card(:data="app" v-for="app in apps")
  .lang-menu
    lang-selector(:hide-label="true")
</template>
<script>
export default {
  data() {
    return {
      filteredApps: [],
    }
  },
  computed: {
    apps() {
      return this.filteredApps
    },
  },
  methods: {
    async filterApps() {
      const apps = Object.entries(this.$config.apps)
      const filtered = []

      for (const [appName, v] of apps) {
        const role = this.$root?.profile?.role
        const applications = this.$root?.profile?.applications || []

        const meetsConditions =
          role === 'admin' ||
          applications.includes(appName) ||
          (role === 'user' && appName === 'digital-edition') ||
          (role === 'user' &&
            appName === 'client-edition' &&
            v.userRoleAuthorizedScreens &&
            v.userRoleAuthorizedScreens.length) ||
          (await $root.$policy?.checkPolicy('', appName))

        if (meetsConditions && v.listed) {
          filtered.push(appName)
        }
      }

      this.filteredApps = filtered
    },
  },
  mounted() {
    this.filterApps()
  },
}
</script>
