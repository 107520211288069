import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useContribution() {
  const getContribution = async ({ isinShare, benchmarkId, dimensions, domain, asof, externalOptions }) => {
    const { sendQuery } = useGraphQL(config)
    const ids = benchmarkId
      ? [
          {
            fuid: isinShare,
            name: 'fund',
          },
          {
            fuid: benchmarkId,
            name: 'benchmark',
          },
        ]
      : [
          {
            fuid: isinShare,
            name: 'fund',
          },
        ]
    const query = `#graphql
      query Contribution($ids: FuidInput!, $dimensions: [String], $domain: Domain!, $asof: Date, $externalOptions: JSON) {
        inventory {
          contribution(fuid: $ids, dimensions: $dimensions, domain: $domain, asof: $asof, externalOptions: $externalOptions) {
            axis
            contribution
            pnlOrFeesPerFund
            pnlOrFeesPerShare
            fuid
            name
            startDate
            endDate
          }
        }
      }`
    const variables = {
      ids,
      dimensions,
      domain,
      asof,
      externalOptions,
    }
    const resp = await sendQuery(query, variables)
    const valueKeys = ['contribution', 'pnlOrFeesPerFund', 'pnlOrFeesPerShare']
    return resp.inventory.contribution.map(d => {
      valueKeys.forEach(key => {
        d[`${key}_fund`] = d[key].fund
        d[`${key}_benchmark`] = d[key].benchmark
        d[`${key}_diff`] = d[key].fund - d[key].benchmark
      })
      return d
    })
  }
  return {
    getContribution,
  }
}
