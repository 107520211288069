<template>
  <popup :show="show">
    <template v-slot:header>{{ $root.t.create_image_folder_popup.title }}</template>
    <template v-slot:content>
      <div class="form">
        <div class="column">
          <label>{{ $root.t.create_image_folder_popup.name_label }}</label>
          <input
            style="width: 100%"
            type="text"
            v-model="form.name"
            :placeholder="$root.t.create_image_folder_popup.name_placeholder"
          />
        </div>
        <div class="column">
          <label>{{ $root.t.create_image_folder_popup.speciality_label }}</label>
          <select v-model="form.specialtyName">
            <option v-for="specialty in specialties" :key="specialty.name" :value="specialty.name">
              {{ specialty.name }}
            </option>
          </select>
        </div>
        <div v-if="form.specialtyName" class="column">
          <label>{{ $root.t.create_image_folder_popup.variables_label }}</label>
          <div class="form-variables">
            <div
              class="form-variables-item"
              v-for="variable in form.variables"
              :key="variable.name"
              @click="variable.isEnabled = !variable.isEnabled"
            >
              <input type="checkbox" v-model="variable.isEnabled" @click.stop />
              <span>{{ variable.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:action>
      <button class="secondary-action" @click="$emit('close')">
        {{ $root.t.create_image_folder_popup.cancel }}
      </button>
      <button class="main-action" @click="createImageFolder" :disabled="!form.name || !form.specialtyName">
        {{ $root.t.create_image_folder_popup.create }}
      </button>
    </template>
  </popup>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue'
import { Specialty } from '@100-m/hauru/src/services/SpecialtyService'
import imageFolderService from '@100-m/hauru/src/services/ImageFolderService'
import { defaultVariableSettings, variableTypes } from '@100-m/hauru/src/components/form/drVariables/lib'

const props = defineProps<{
  show: boolean
  specialties: Specialty[] | null
}>()

const emit = defineEmits(['close', 'created'])

const form = ref<{
  name: string | null
  variables: { name: string; isEnabled: boolean }[] | null
  specialtyName: string | null
}>({
  name: null,
  variables: null,
  specialtyName: null,
})

watch(
  () => form.value.specialtyName,
  () => {
    const variablesForSpecialty =
      props.specialties?.find(specialty => specialty.name === form.value.specialtyName)?.settings || []

    form.value.variables = variablesForSpecialty.map(variable => ({
      name: variable.name,
      isEnabled: false,
    }))
  },
)

async function createImageFolder() {
  let jsonVariables = form.value.variables?.flatMap(variable => (variable.isEnabled ? [variable.name] : [])) || []

  const variablesForSpecialty =
    props.specialties?.find(specialty => specialty.name === form.value.specialtyName)?.settings || []

  const missingDependenciesVariables = jsonVariables?.reduce((acc, variable) => {
    const specialtyVariableType =
      variablesForSpecialty.find((specialtyVariable: { name: string }) => specialtyVariable.name === variable)
        ?.variableType || null

    const setting = defaultVariableSettings.find(setting => setting.variableType === specialtyVariableType)

    const variableType = setting ? variableTypes[setting?.variableType] : null

    if (variableType?.dependencies) {
      const missingDependencies = variableType.dependencies.filter(dependency => !jsonVariables.includes(dependency))

      const nestedDependencies = missingDependencies.flatMap(dependency => {
        const dependencyType = variableTypes[dependency]
        return dependencyType?.dependencies || []
      })

      acc.push(...missingDependencies, ...nestedDependencies)
    }

    return acc
  }, [] as string[])

  jsonVariables = [...new Set(jsonVariables.concat(missingDependenciesVariables))]

  try {
    const res = await imageFolderService.create({
      imageFolderInput: {
        name: form.value.name,
        specialtyName: form.value.specialtyName,
        variables: jsonVariables,
      },
    })

    emit('created', res)
  } catch (error) {
    return $root.toast({ description: error?.message, type: 'error', timeout: 5000 })
  }
}
</script>

<style scoped>
.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
}
.form-variables {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.form-variables-item {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}
</style>
