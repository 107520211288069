import firebase from 'firebase/app'
import 'firebase/storage'
import clientConfig from './clientsConfig.json' assert { type: 'json' }

export default class GoogleFileSystem {
  #bucket
  #bucketName

  constructor(config) {
    const { root, name, project, apiKey } = config

    if (!root && !name && !project) return

    const clientName = name.replace('-default-rtdb', '')

    this.#bucketName = root

    if (!firebase.apps.length) {
      firebase.initializeApp({
        apiKey,
        authDomain: `${name}.firebaseapp.com`,
        storageBucket: clientConfig[clientName].storageBucket,
        databaseURL: `https://${name}.firebaseio.com`
      })
    }

    this.#bucket = firebase.storage().ref()
  }

  async upload({ buffer, filenameWithPath }) {
    console.log('PUT FILE', filenameWithPath)
    const fileRef = this.#bucket.child(
      `${this.#bucketName}/${filenameWithPath}`
    )
    await fileRef.put(buffer)
  }

  async get({ filenameWithPath }) {
    console.log('GET FILE', filenameWithPath)
    const fileRef = this.#bucket.child(
      `${this.#bucketName}/${filenameWithPath}`
    )
    const url = await fileRef.getDownloadURL()
    const response = await fetch(url)
    return await response.blob()
  }

  async deleteFile({ filenameWithPath }) {
    const fileRef = this.#bucket.child(
      `${this.#bucketName}/${filenameWithPath}`
    )
    await fileRef.delete()
  }

  async deleteFolder({ filenameWithPath }) {
    const files = await this.#bucket
      .child(`${this.#bucketName}/${filenameWithPath}`)
      .listAll()
    await Promise.all(files.items.map((file) => file.delete()))
  }
}
