<script setup lang="ts">
import * as d3 from 'd3'
import { onMounted, ref, computed, watch } from 'vue'
import { getUniqueCategories, pivotData } from './data-utils'
const element = ref<HTMLElement>()
const { data, options = {} } = defineProps(['data', 'options'])
const { x, y } = options

const draw = (width, height, margin) => {
  const formatInt = (value, digit) => Math.round(value * Math.pow(10, digit)) / Math.pow(10, digit)
  const ticks = (data, { num = 5, digit = 0, flag = true } = {}) => {
    const min = formatInt(Math.min(...data), digit)
    const max = formatInt(Math.max(...data), digit)
    const gap = formatInt((max - min) / (num - 1), digit)
    const ticks = Array(num)
      .fill()
      .map((_, i) => formatInt(min + i * gap, digit))
    if (flag && gap === max - ticks.at(-1)) return ticks.concat(max)
    return ticks
  }
  const xData = data.map(v => v[x])
  const categories = getUniqueCategories(data, options)
  const yData = data.flatMap(d => d.group.map(g => g[options.y]))
  const xScale = d3
    .scaleTime()
    .domain(d3.extent(xData))
    .range([margin.left + 5, width - margin.right])
  const yScale = d3
    .scaleLinear()
    .domain(d3.extent(yData.flat()))
    .range([height - margin.bottom, margin.top])

  const xTicks = xScale.ticks(4)
  const yTicks = yScale.ticks(5) //ticks(yData, { num: Math.min(5, Math.round(height / 30)) })
  const line = cat =>
    d3
      .line()
      .x(v => xScale(v[x]))
      .y(v => yScale(+v[cat]))
  const lineData = pivotData(data, options, categories)
  return { xScale, yScale, xTicks, yTicks, line, categories, lineData }
}

const width = ref(300)
const height = ref(150)
const margin = ref({ top: 10, bottom: 20, right: 0, left: 20 })
const xScale = ref(() => null)
const yScale = ref(() => null)
const xTicks = ref([])
const yTicks = ref([])
const line = ref(() => () => null)
const categories = ref([])
const lineData = ref([])

const adjustLeftMargin = () => {
  if (!element.value || !yTicks.value.length) {
    return
  }

  const maxWidth = Math.max(...yTicks.value.map(v => options.formatY(v)?.length || 0))
  margin.value.left = 10 + maxWidth * 5
}

watch(yTicks, () => {
  adjustLeftMargin()
})

function redraw() {
  if (!element.value?.clientWidth) return
  width.value = element.value.clientWidth
  height.value = element.value.clientHeight

  const x = draw(width.value, height.value, margin.value)
  xScale.value = x.xScale
  yScale.value = x.yScale
  xTicks.value = x.xTicks
  yTicks.value = x.yTicks
  line.value = x.line
  categories.value = x.categories
  lineData.value = x.lineData
}

let init = false
const resizeObserver = new ResizeObserver(() => {
  if (!init) {
    init = true
    return
  }
  redraw()
})
onMounted(() => {
  redraw()
  element.value && resizeObserver.observe(element.value)
})
</script>

<template>
  <svg class="nx-line w-full max-w-full overflow-visible" xmlns="http://www.w3.org/2000/svg" ref="element">
    <g v-for="v in yTicks" :key="v" class="axis y" :transform="`translate(${margin.left},${yScale(v)})`">
      <line :x1="0" :x2="width - margin.right - margin.left" />
      <text alignment-baseline="middle" x="-5" text-anchor="end">{{ options.formatY(v) }}</text>
    </g>
    <g v-for="v in xTicks" :key="v" class="axis x" :transform="'translate(' + xScale(v) + ',' + height + ')'">
      <text text-anchor="middle">{{ options.formatX(v) }}</text>
    </g>
    <path
      class="line"
      :d="line(category)(lineData)"
      :class="category"
      :stroke="options.palette[idx]"
      fill="transparent"
      :key="idx"
      v-for="(category, idx) in categories"
    />
  </svg>
</template>

<style scoped>
.nx-line .axis text {
  font-size: calc(var(--text_size) * (0.8));
  fill: rgba(0, 0, 0, 0.6);
}
.nx-line .axis line {
  stroke: rgba(0, 0, 0, 0.2);
  stroke-width: 1px;
}
.nx-line .line {
  stroke-width: 1.5px;
}
</style>
