<template>
  <div class="flex-1">
    <img v-if="imageUrl" class="m-auto h-full w-full object-contain" :src="imageUrl" />
    <div v-else class="rounded-md !bg-red-50 p-2 !outline !outline-red-500">Error with image</div>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { BuilderProps } from '../composables/builderComponent'
import { commandrSDKFileSystem } from '@100-m/hauru/src/features/commandr'

type PropsData = {
  path: string
}

const props = defineProps<BuilderProps>()

const imageUrl = computed(() => {
  const data = props?.data as PropsData

  if (!data?.path) {
    return null
  }

  const publicUrl = ['docker', 'aws'].includes((import.meta as any).env.VITE_COMMANDR_FILESYSTEM_MODE)
    ? commandrSDKFileSystem.getPublicUrl({ filenameWithPath: data.path })
    : `${(import.meta as any).env.VITE_COMMANDR}/${data.path}`

  return publicUrl || null
})
</script>
