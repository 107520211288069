<template>
  <!-- :class="{ '!table-auto': _props._options.auto }" -->
  <table class="nx-table all-table leading-sm bottom-0 h-fit w-full table-fixed rounded-lg">
    <colgroup>
      <col v-for="(col, idx) in columns" :class="'column-' + idx" class="table-col" :key="idx" />
    </colgroup>
    <slot :columns="columns" name="header" :formatLabel="options.formatLabel" :options="options">
      <tr class="table-header font-bold">
        <th class="table-header-cell px-2 py-1" v-for="col in columns" :key="col">
          <div v-if="col !== options.x" v-html="options.formatLabel(col)"></div>
        </th>
      </tr>
    </slot>
    <tr class="table-row rounded-lg" v-for="(line, idx) in tableData" :key="idx">
      <td
        class="table-cell overflow-hidden whitespace-nowrap px-2 py-1"
        v-for="col in columns"
        :key="col"
        v-html="options.formatY(line[col])"
      ></td>
    </tr>
  </table>
</template>
<script setup lang="ts">
import { computed } from 'vue'
import { getUniqueCategories, pivotData } from './data-utils'
const props = defineProps(['data', 'options'])
const categories = computed(() => getUniqueCategories(props.data, props.options))
const tableData = computed(() => pivotData(props.data, props.options, categories.value))
const columns = computed(() => Object.keys(tableData.value?.[0]).filter(k => k !== 'group'))
</script>
<style scoped>
.nx-table .nx-table-row:nth-child(even) {
  background-color: rgb(var(--primary), 0.1);
}
</style>
