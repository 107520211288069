<template>
  <div class="builder-footer flex h-fit flex-col justify-between">
    <div class="footer-container">
      <div class="footer-title" v-if="options.title" v-html="translate(options.title)"></div>
      <div class="footer-subtitle" v-if="options.subtitle" v-html="translate(options.subtitle)"></div>
      <div class="footer-footnotes flex h-fit w-full flex-row items-center justify-between">
        <div class="start flex flex-col justify-between">
          <div v-for="(v, k) in pageFootnotes" class="footnote flex flex-row" :key="k">
            <sup class="footnote-marker">{{ v.index }}</sup>
            <span class="footnote-text leading-[11.2px]" v-html="v.value"></span>
          </div>
        </div>
        <!-- <img class="ml-2 mr-2 h-12 p-1" :src="footerImg" alt="Logo LFR" /> -->
        <div class="page-indicator">{{ pageNumber }}/{{ pageCount }}</div>
      </div>
      <div class="footer-disclaimer" v-if="options.disclaimer" v-html="translate(options.disclaimer)"></div>
    </div>
  </div>
</template>

<style>
.builder-footer {
  margin-top: auto !important;
}
</style>

<script setup>
import usePagination from '../composables/pagination'
import useTranslations from '../composables/translations'
import { footer as story } from './stories'
const props = defineProps(['data', 'options', 'context'])
const { pageFootnotes, translate } = useTranslations(props, 'page')

// const disclaimer = computed(() => {
//   const disc = data['nxpack-fund-ref'].disclaimer_footer || data.translation.disclaimer_footer
//     if (typeof disc !== 'string') return disc
//     return insane(marked(disc))
// })
const { pageCount, pageNumber } = usePagination(props)
// onMounted(() => {
//   initPagination()
// })
// const footerImg = computed(() => options.layout.theme?.assets?.find(el=>el.name==='logo-footer.png')?.src || '/logo-footer.png' )
</script>
<script>
export default {
  styles: {
    '.footer-title': {
      name: 'Footer title',
      css: `font-size: 10px;`,
    },
    '.footer-subtitle': {
      name: 'Footer subtitle',
      css: `font-size: 7px;`,
    },
    '.footer-footnotes': {
      name: 'Footer footnotes',
      css: `padding: 8px 24px;`,
    },
    '.footnote': {
      name: 'Footnote',
      css: `margin-bottom: 2px;`,
    },
    '.footnote-text': {
      name: 'Footnote text',
      css: `font-size: 7px;`,
    },
    '.footnote-marker': {
      name: 'Footnote marker',
      css: `font-size: 7px;`,
    },
    '.page-indicator': {
      name: 'Page indicator',
      css: `font-size: 10px;`,
    },
    '.footer-disclaimer': {
      name: 'Footer Disclaimer',
      css: `font-size: 7px;`,
    },
    '.footer-container': {
      root: true,
      name: 'Footer container',
      css: `border-top: 2px solid var(--primary);`,
    },
  },
  story,
}
</script>
