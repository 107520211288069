<template lang="pug">
transition(@leave='leave')
  loader(v-if="!this.loaded")
Error(v-if="error" :textHeader="t['error'] || 'Error'" :textBody="error.message")
template(v-if="this.loaded && !this.error")
  .screen-title
    h1 {{ t['performance'] }} 
  .row.stretch
    kpi.performance(
      v-if="performanceKpi"
      :data="performanceKpi"
    )
    kpi.volatility(
      v-if="showAnalytics && volatilityKpi"
      :data="volatilityKpi" 
    )
    kpi.drawdown(
      v-if="drawdownDetailsKpi"
      :data="drawdownDetailsKpi"
    )
    kpi(
      v-if="showAnalytics && analyticsKpi"
      :data="analyticsKpi"
    )
  .block.column.expand
    block(
      :title="performance_type_title(data)"
      type="plot-line"
      v-if="performanceHistory"
      :data="domain_historical_formatted"
      :metadata="{format: (v) => format('.2%')(v * 0.01)}"
    )
    block.allow-fullscreen(
      type="plot-bar"
      v-if="isChartYearly ? yearlyPerformances : monthlyPerformances"
      :data="isChartYearly ? yearlyPerformances : monthlyPerformances"
      :title="isChartYearly ?  'performance_yearly' : 'performance_monthly' "
      :metadata="{ sort: sortMonths }"
      @plotclick="handleBarChartClick"
    )
      template(v-slot:title="{ title, toggleFullscreen }")
        h2
          div(v-html="t[title] || title")
          select(@change="update_query({ domain: $event.target.value })" v-if="yearlyPerformancesRaw.keys().length > 1")
            option(value="") {{ t.default }}
            option(:selected="$route.query.domain && $route.query.domain.length <= 7 && year === $route.query.domain.slice(0, 4)" v-for="year in yearlyPerformances.keys().reverse()") {{ year }}
          button.ghost(aria-label="fullscreen" @click="toggleFullscreen()")
            svg-icon.nx-fullscreen(name="nx-fullscreen")
</template>

<script>
import Block from '@100-m/hauru/src/components/to-remove/block.vue'

export const additions = { calendar: 'period', icon: 'ic_timeline' }
import { useDates } from '../../composables/useDates'
import { usePerformance } from '../../composables/usePerformance.js'
import { useShare } from '../../composables/useShare.js'
import Error from '@100-m/hauru/src/components/ui/error.vue'

const { getPerformanceData } = usePerformance()
const { getShare } = useShare()
const { getPerformanceDates } = useDates()

export default {
  components: { Error, Block },
  data() {
    return {
      default_domain: '0001-01-01|' + new Date().toISOString().split('T')[0],
      displayed_plot: 'performance_historical',
      loaded: false,
      error: null,
      params: {
        isinShare: this.$route.fullPath.split('/')[2].split('-')[1],
        domain: $root.domain.join('|') || this.$route.query.domain,
        asof: $root.asof,
        lang: this.$route.query.lang || $root.lang,
        metric: this.$route.query.metric,
      },
      performanceHistory: null,
      yearlyPerformancesRaw: null,
      monthlyPerformancesRaw: null,
      performanceKpisRaw: null,
      share: null,
      data: {},
    }
  },
  computed: {
    rootWatcher() {
      return this.$route.query.domain || $root.query.domain || $root.valuationStyle
    },
    metricWatcher() {
      return this.$route.query.metric
    },
    sortMonths() {
      return d =>
        [...Array(12).keys()]
          .map(i => new Intl.DateTimeFormat($root.lang.slice(0, 2), { month: 'short' }).format(new Date(String(i + 1))))
          .indexOf(d.x)
    },
    isChartYearly() {
      // NOTE: I dont get this
      return (!this.$route.query.domain || this.$route.query.domain.length > 7) && this.yearlyPerformancesRaw.length > 1
    },
    yearlyPerformances() {
      if (!this.yearlyPerformancesRaw) return
      const metric = this.$route.query.metric || 'fund'
      return this.yearlyPerformancesRaw.reduce((acc, v) => {
        acc[v.date] = metric === 'diff' ? v.fund - v.benchmark : v[metric]
        return acc
      }, {})
    },
    monthlyPerformances() {
      if (!this.monthlyPerformancesRaw) return
      const metric = this.$route.query.metric || 'fund'
      return this.monthlyPerformancesRaw.reduce((acc, v) => {
        const formattedDate = new Intl.DateTimeFormat($root.lang.slice(0, 2), { month: 'short' }).format(
          new Date(v.date),
        )
        acc[formattedDate] = metric === 'diff' ? v.fund - v.benchmark : v[metric]
        return acc
      }, {})
    },
    performanceKpi() {
      if (!this.performanceKpisRaw?.performance) return
      const kpis = [
        {
          title: this.performance_type_title(this.share),
          value: this.performanceKpisRaw.performance.fund,
        },
      ]
      // NOTE: we used to take this.data.share.analytics.domainPerformance[this.data.share.benchmark.label], does this mean we skip the chained benchmark ?
      if (this.performanceKpisRaw.performance.benchmark) {
        kpis.push({
          title: 'performance_benchmark',
          value: this.performanceKpisRaw.performance.benchmark,
        })
      }
      return kpis
    },
    volatilityKpi() {
      if (!this.performanceKpisRaw?.volatility) return
      const kpis = [
        {
          title: 'volatility',
          value: this.performanceKpisRaw?.volatility.fund,
        },
      ]
      if (this.performanceKpisRaw?.volatility.benchmark) {
        kpis.push({
          title: 'volatility_benchmark',
          value: this.performanceKpisRaw?.volatility.benchmark,
        })
      }
      return kpis
    },
    drawdownDetailsKpi() {
      const metric = this.params?.metric || 'fund'
      if (metric === 'diff' || !this.performanceKpisRaw?.drawdownDetails) return
      const drawdownDetails = this.performanceKpisRaw?.drawdownDetails[metric]
      return drawdownDetails.keys().map(key => ({ title: key, value: drawdownDetails[key] }))
    },
    analyticsKpi() {
      const metric = this.params?.metric || 'fund'
      if (metric === 'diff' || !this.performanceKpisRaw) return
      let benchmarkKpi = []
      if (this.params.benchmarkId) {
        benchmarkKpi = [
          { title: 'trackingError', value: parseFloat(this.performanceKpisRaw?.trackingError) },
          { title: 'informationRatio', value: parseFloat(this.performanceKpisRaw?.informationRatio) },
          { title: 'sharpe_ratio_benchmark', value: parseFloat(this.performanceKpisRaw.sharpeRatio.benchmark) },
        ]
      }
      return [...benchmarkKpi, { title: 'sharpe_ratio', value: parseFloat(this.performanceKpisRaw.sharpeRatio.fund) }]
    },
    domain_historical_formatted() {
      // todo: check if really needed
      // Get the first benchmark in the list, if it's empty, use 'benchmark'
      // const benchmark_multiple = this.data.share.characteristics?.benchmark_multiple?.split(',')[0]
      // const test = this.data.share.analytics.domainHistorical.reduce((acc, a) => {
      //   let doc = {}
      //   if (a.fund) doc.fund = a.fund - 100
      //   if (a.benchmark && (!benchmark_multiple || benchmark_multiple === 'benchmark'))
      //     doc.benchmark = a.benchmark - 100
      //   if (a.benchmark2 && benchmark_multiple === 'benchmark2') doc.benchmark2 = a.benchmark2 - 100
      //   if (a.benchmark3 && benchmark_multiple === 'benchmark3') doc.benchmark3 = a.benchmark3 - 100
      //   acc[a.date] = doc
      //   return acc
      // }, {})
      // TODO: Handle multi benchmark
      return this.performanceHistory.reduce((acc, v) => {
        acc[v.date] = { fund: v.fund - 100, benchmark: v.benchmark - 100 }
        return acc
      }, {})
    },
    showAnalytics() {
      // Original: data?.share?.analytics?.volatility?.fund && (!is_less_1y || !$root.config.hideLessThanOneYear)
      // This is true if less_1y is true and config.hideLessThanOneYear undefined
      // if (!this.performanceKpisRaw?.volatility?.fund) return false
      return !this.is_less_1y || !$root.config.hideLessThanOneYear
    },
    is_less_1y() {
      // Do not show volatility and risk indicators if < 1y
      const days = (new Date($root.domain[1]) - new Date($root.domain[0])) / 86400000
      return days < 363
    },
  },
  watch: {
    async rootWatcher(newRoot, oldRoot) {
      if (newRoot?.toString() === oldRoot?.toString()) return
      this.params.domain = newRoot?.join('|')
      if (this.$route.query.domain === undefined) {
        this.params.domain = this.default_domain
      } else {
        this.params.domain = this.$route.query.domain
      }
      await this.loadData()
    },
    async metricWatcher(newM, oldM) {
      this.params.metric = newM
      await this.loadData()
    },
  },
  async created() {
    await this.loadData()
  },
  methods: {
    leave(el, done) {
      el.style.opacity = 0
      setTimeout(() => done(), 500)
    },
    async loadData() {
      this.loaded = false
      this.error = null
      try {
        if (!$root.dates || $root.dates.isin_share !== this.params.isinShare || $root.dates.query !== 'performance') {
          $root.dates = await getPerformanceDates(this.params)
          $root.dates.isin_share = this.params.isinShare
          $root.dates.query = 'performance'
          $root.refresh = $root.refresh + 1
          this.params.domain = $root.domain.join('|')
        }
        this.share = await getShare(this.params)
        this.params.benchmarkId = this.share.benchmarkId
        this.params.riskFreeBenchmarkId = this.share.riskFreeBenchmarkId
        const { kpis, yearlyPerformances, monthlyPerformances, domainHistorical } = await getPerformanceData(
          this.params,
        )
        this.performanceHistory = domainHistorical
        this.yearlyPerformancesRaw = yearlyPerformances
        this.monthlyPerformancesRaw = monthlyPerformances
        this.performanceKpisRaw = kpis
        $root.tab_benchmark = { id: this.share.benchmarkName, label: 'benchmark' } // this.data.share.benchmark
        $root.tab_userflow_name = $root.$route.fullPath.split('/')?.[2]?.split('-')?.[0] // this.data.share.slug
        $root.nav_data.path = this.share.characteristics?.share_letter
        $root.refresh = $root.refresh + 1
      } catch (e) {
        this.error = e
        console.error(e)
      } finally {
        this.loaded = true
      }
    },
    handleBarChartClick($event) {
      if (this.isChartYearly) {
        update_query({ domain: $event.x })
      } else {
        const year = this.$route.query.domain.slice(0, 4)
        const month = `${this.sortMonths($event) + 1}`.padStart(2, '0')
        const domain = `${year}-${month}`
        update_query({ domain })
      }
    },
    performance_type_title(share) {
      // NOTE: characteristics?.mandat_is_perf_net does not exist in schema (seems like a lafrançaise specific thing)
      if (share.characteristics?.mandat_is_perf_net || share.characteristics?.mandat_is_perf_net === '')
        return 'performance_net'
      return 'performance_brut'
    },
  },
}
</script>
<style>
.kpi.benchmark.expand {
  min-width: 450px;
}
.kpi .plus {
  min-width: 400px;
}
</style>
