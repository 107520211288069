<template>
  <fieldset class="my-3 flex items-center text-sm">
    <slot name="label">
      <label class="w-32 shrink-0" v-if="label">{{ label }}</label>
    </slot>
    <select
      class="w-48 rounded bg-neutral-600 p-1 [font-size:inherit] focus:outline-none focus-visible:ring-1"
      v-model="value"
    >
      <option></option>
      <option v-for="(formatData, idx) in dateFormats" :key="idx" :value="formatData.value">
        {{ formatData.name }}
      </option>
    </select>
  </fieldset>
</template>
<script setup lang="ts">
defineProps(['context', 'label'])
const value = defineModel()
const dateFormats = [
  {
    name: 'Standard',
    value: {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    },
  },
  {
    name: 'Short',
    value: {
      dateStyle: 'short',
    },
  },
  {
    name: 'Medium',
    value: {
      dateStyle: 'medium',
    },
  },
  {
    name: 'Long',
    value: {
      dateStyle: 'long',
    },
  },
  {
    name: 'Full',
    value: {
      dateStyle: 'full',
    },
  },
  {
    name: 'Year and Month',
    value: {
      year: 'numeric',
      month: '2-digit',
    },
  },
  {
    name: 'Year only',
    value: {
      year: 'numeric',
    },
  },
  {
    name: 'Custom format',
    value: 'custom',
  },
]
</script>
