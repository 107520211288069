<style>
:root {
  --graph-bg-secondary: var(--colors-secondary);
  --graph-bg-third: var(--colors-secondary);
  --graph-padding: 4px 0;
  --graph-margin: 8px;
  --graph-height: 8px;
  --graph-border-radius: 2px;
  --table-font-family: Apercu, sans-serif;
  --table-font-size: 8px;
  --table-bg-row: var(--colors-primary-default-light-line);
  --table-row-height: 16px;
  --table-row-padding: 0px;
  --table-line-height: 11.2px;
  --table-footer-font-weight: 600;
  --value-margin: 4px;
}
.horizontalTableContainer {
  margin-left: 8px;
}
.horizontalTableContainer td,
.horizontalTableContainer thead,
.horizontalTableContainer tfoot {
  padding: 0 4px !important;
}
.horizontalTableContainer th,
.horizontalTableContainer tr,
.horizontalTableContainer td {
  border-collapse: collapse;
  border: none !important;
  box-shadow: none !important;
  height: var(--table-row-height);
  box-shadow: none !important;
  font-size: var(--table-font-size);
}
.horizontalTableContainer table {
  width: 100%;
  box-shadow: none !important;
  border-collapse: collapse;
  border: none !important;
  font-size: var(--table-font-size);
  font-family: var(--table-font-family);
}
.horizontalTableContainer table thead {
  font-weight: 600;
  line-height: var(--table-line-height);
}
.horizontalTableContainer table tfoot {
  font-weight: var(--table-footer-font-weight);
  line-height: var(--table-line-height);
}
.horizontal_table td,
.horizontal_table th {
  border: none !important;
  text-align: left;
  padding: var(--table-row-padding);
}
.horizontal_table tfoot {
  font-weight: bold !important;
}
.horizontal_table tr:nth-child(even) {
  background-color: var(--table-bg-row);
  border: none !important;
}
.graph_carbon {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.graph_carbon__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0;
}
.graph_one {
  display: flex;
  background: var(--primary);
}
.graph_two {
  display: flex;
  background: var(--graph-bg-secondary);
}
.graph_tree {
  display: flex;
  background: var(--graph-bg-third);
}
.graph_one,
.graph_two,
.graph_tree {
  height: var(--graph-height);
  padding: var(--graph-padding);
  box-sizing: border-box;
  border-radius: var(--graph-border-radius);
}
.graph_carbon__positive {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex: 1;
}
.graph_carbon__negative {
  display: flex;
  flex-direction: row-reverse;
  justify-content: flex-end;
  flex: 1;
}
.cardone_value__one {
  display: block;
  padding-left: var(--graph-margin);
}
.cardone_value__two {
  display: block;
  margin-right: var(--value-margin);
}
.cardone_value__three {
  display: block;
  text-align: right !important;
  margin-left: var(--value-margin);
}
.graph_footer__total {
  display: flex;
  justify-content: center;
}
</style>
<template lang="pug">
.horizontalTableContainer
  table.horizontal_table
    thead
      tr
        th(v-for="item in data[0]") {{ $root.t[item] || item }}
    tbody
      tr(v-for="item in data.slice(1)")
        td {{ t[item[0]] || item[0]  }}
        td {{ format('.2%')(item[1]) }}
        td.graph_carbon
          .graph_one(:style="{ width: getCarbonWidth(item[2]) }")
          .cardone_value__one {{ format('.0f')(item[2]) }}
        td
          .graph_carbon__container
            .graph_carbon__positive
              .cardone_value__two(v-if="item[3] <= 0") {{ format('.0f')(item[3]) }}
              .graph_two(v-if="item[3] <= 0" :style="{ width: getCarbonWidth(Math.abs(item[3]))}")
            .graph_carbon__negative
              .cardone_value__three(v-if="item[3] > 0") {{ format('.0f')(item[3]) }}
              .graph_tree(v-if="item[3] > 0" :style="{ width: getCarbonWidth(Math.abs(item[3])) }")
    tfoot
      tr.graph_footer
        td Total
        td
        td {{ format('.0f')(carbonTotal) }}
        td.graph_footer__total {{ format('.0f')(carbonGreenTotal) }}
</template>

<script>
export const additions = {}
export default {
  props: { data: Array },
  computed: {
    carbonTotal() {
      return this.data.slice(1).sum(d => d[2])
    },
    carbonGreenTotal() {
      return this.data.slice(1).sum(d => d[3])
    },
  },
  methods: {
    getCarbonWidth(value) {
      if (!value) return 0
      return value + '%'
    },
  },
}
</script>
