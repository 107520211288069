// 2D Bar = contribution / sector
export const contributionPerSector = [
  { key: 'Energy', value: 0.001 },
  { key: 'Materials', value: -0.002 },
  { key: 'Industrials', value: 0.003 },
  { key: 'Consumer Discretionary', value: 0.004 },
  { key: 'Health Care', value: -0.005 },
  { key: 'Financials', value: 0.006 },
  { key: 'Information Technology', value: 0.007 },
  { key: 'Communication Services', value: -0.008 },
  { key: 'Utilities', value: 0.009 },
  { key: 'Real Estate', value: -0.01 },
]
export const contributionPerSubsector = [
  { key: 'Energy Equipment & Services', value: 0.002, category: 'Energy' },
  { key: 'Oil, Gas & Consumable Fuels', value: -0.001, category: 'Energy' },
  { key: 'Chemicals', value: -0.002, category: 'Materials' },
  { key: 'Industrials Conglomerates', value: 0.003, category: 'Industrials' },
  { key: 'Aerospace & Defense', value: 0.004, category: 'Industrials' },
  { key: 'Automobiles', value: -0.002, category: 'Consumer Discretionary' },
  { key: 'Household Durables', value: 0.006, category: 'Consumer Discretionary' },
  { key: 'Biotechnology', value: -0.005, category: 'Health Care' },
  { key: 'Banks', value: 0.004, category: 'Financials' },
  { key: 'Capital Markets', value: 0.002, category: 'Financials' },
  { key: 'Communications Equipment', value: 0.007, category: 'Information Technology' },
  { key: 'Entertainment', value: -0.008, category: 'Communication Services' },
  { key: 'Electric Utilities', value: 0.009, category: 'Utilities' },
  { key: 'Equity Real Estate Investment Trusts', value: -0.01, category: 'Real Estate' },
]
// 2D Pie = allocation / sector
export const allocationPerSector = [
  { key: 'Energy', value: 0.3 },
  { key: 'Materials', value: 0.2 },
  { key: 'Industrials', value: 0.1 },
  { key: 'Consumer Discretionary', value: 0.05 },
  { key: 'Health Care', value: 0.05 },
  { key: 'Financials', value: 0.025 },
  { key: 'Information Technology', value: 0.025 },
  { key: 'Communication Services', value: 0.025 },
  { key: 'Utilities', value: 0.025 },
  { key: 'Real Estate', value: 0.2 },
]
// 2D Line = valuation / period
export const valuation = Array(252).fill(0)
valuation.forEach((_, i) => {
  const value = i === 0 ? 100 : valuation[i - 1].value * (1 + (Math.random() - 0.5) / 10)
  const workday = Math.floor((i * 7) / 5)
  valuation[i] = {
    date: new Date(2020, 0, workday),
    value: value,
  }
})
// 3D Sunburst = allocation / sector AND subsector
export const allocationPerSubsector = [
  { key: 'Energy Equipment & Services', value: 0.12, category: 'Energy' },
  { key: 'Oil, Gas & Consumable Fuels', value: 0.18, category: 'Energy' },
  { key: 'Chemicals', value: 0.02, category: 'Materials' },
  { key: 'Construction Materials', value: 0.12, category: 'Materials' },
  { key: 'Containers & Packaging', value: 0.02, category: 'Materials' },
  { key: 'Metals & Mining', value: 0.04, category: 'Materials' },
  { key: 'Aerospace & Defense', value: 0.1, category: 'Industrials' },
  { key: 'Automobiles', value: 0.05, category: 'Consumer Discretionary' },
  { key: 'Biotechnology', value: 0.05, category: 'Health Care' },
  { key: 'Banks', value: 0.025, category: 'Financials' },
  { key: 'Communications Equipment', value: 0.025, category: 'Information Technology' },
  { key: 'Entertainment', value: 0.025, category: 'Communication Services' },
  { key: 'Electric Utilities', value: 0.025, category: 'Utilities' },
  { key: 'Equity Real Estate Investment Trusts', value: 0.2, category: 'Real Estate' },
]
// 3D ClusterBar = contribution / sector x compare
export const contributionPerSectorCompare = [
  { key: 'Energy', value: 0.001, category: 'GRK Sport' },
  { key: 'Materials', value: -0.002, category: 'GRK Sport' },
  { key: 'Industrials', value: 0.003, category: 'GRK Sport' },
  { key: 'Consumer Discretionary', value: 0.004, category: 'GRK Sport' },
  { key: 'Health Care', value: -0.005, category: 'GRK Sport' },
  { key: 'Financials', value: 0.006, category: 'GRK Sport' },
  { key: 'Information Technology', value: 0.007, category: 'GRK Sport' },
  { key: 'Communication Services', value: -0.008, category: 'GRK Sport' },
  { key: 'Real Estate', value: -0.01, category: 'GRK Sport' },
  { key: 'Energy', value: 0.002, category: 'MSCI' },
  { key: 'Materials', value: -0.003, category: 'MSCI' },
  { key: 'Industrials', value: 0.004, category: 'MSCI' },
  { key: 'Consumer Discretionary', value: 0.005, category: 'MSCI' },
  { key: 'Health Care', value: -0.006, category: 'MSCI' },
  { key: 'Financials', value: 0.007, category: 'MSCI' },
  { key: 'Information Technology', value: 0.008, category: 'MSCI' },
  { key: 'Communication Services', value: -0.009, category: 'MSCI' },
  { key: 'Utilities', value: 0.019, category: 'MSCI' },
  { key: 'Real Estate', value: -0.011, category: 'MSCI' },
]
// 3D StackBar = attribution / sector
export const attributionPerSector = [
  { key: 'Energy', value: 0.00012, category: 'Allocation' },
  { key: 'Materials', value: -0.00023, category: 'Allocation' },
  { key: 'Industrials', value: 0.00034, category: 'Allocation' },
  { key: 'Consumer Discretionary', value: 0.00045, category: 'Allocation' },
  { key: 'Health Care', value: -0.00056, category: 'Allocation' },
  { key: 'Financials', value: 0.00067, category: 'Allocation' },
  { key: 'Information Technology', value: 0.00078, category: 'Allocation' },
  { key: 'Communication Services', value: -0.00089, category: 'Allocation' },
  { key: 'Utilities', value: 0.00091, category: 'Allocation' },
  { key: 'Real Estate', value: -0.00102, category: 'Allocation' },
  { key: 'Energy', value: -0.00112, category: 'Selection' },
  { key: 'Materials', value: -0.00123, category: 'Selection' },
  { key: 'Industrials', value: -0.00134, category: 'Selection' },
  { key: 'Consumer Discretionary', value: 0.00145, category: 'Selection' },
  { key: 'Health Care', value: 0.00156, category: 'Selection' },
  { key: 'Financials', value: 0.00167, category: 'Selection' },
  { key: 'Information Technology', value: 0.00178, category: 'Selection' },
  { key: 'Communication Services', value: -0.00189, category: 'Selection' },
  { key: 'Utilities', value: -0.00191, category: 'Selection' },
  { key: 'Real Estate', value: -0.00202, category: 'Selection' },
]
// 3D Line = valuation / period x compare
export const valuationCompare = [
  // { date: new Date('2020-01-01'), value: 120, category: 'GRK Sport' },
  // { date: new Date('2020-02-01'), value: 125, category: 'GRK Sport' },
  // { date: new Date('2020-03-01'), value: 115, category: 'GRK Sport' },
  { date: new Date('2020-04-01'), value: 110, category: 'GRK Sport' },
  { date: new Date('2020-05-01'), value: 140, category: 'GRK Sport' },
  { date: new Date('2020-06-01'), value: 145, category: 'GRK Sport' },
  // { date: new Date('2020-07-01'), value: 150, category: 'GRK Sport' },
  // { date: new Date('2020-08-01'), value: 155, category: 'GRK Sport' },
  // { date: new Date('2020-09-01'), value: 160, category: 'GRK Sport' },
  // { date: new Date('2020-10-01'), value: 165, category: 'GRK Sport' },
  { date: new Date('2020-11-01'), value: 170, category: 'GRK Sport' },
  { date: new Date('2020-12-01'), value: 175, category: 'GRK Sport' },
  { date: new Date('2020-01-01'), value: 1, category: 'MSCI' },
  { date: new Date('2020-02-01'), value: 1.3, category: 'MSCI' },
  { date: new Date('2020-03-01'), value: 1.2, category: 'MSCI' },
  { date: new Date('2020-04-01'), value: 1.15, category: 'MSCI' },
  { date: new Date('2020-05-01'), value: 1.45, category: 'MSCI' },
  { date: new Date('2020-06-01'), value: 1.5, category: 'MSCI' },
  { date: new Date('2020-07-01'), value: 1.55, category: 'MSCI' },
  { date: new Date('2020-08-01'), value: 1.6, category: 'MSCI' },
  { date: new Date('2020-09-01'), value: 1.65, category: 'MSCI' },
  { date: new Date('2020-10-01'), value: 1.7, category: 'MSCI' },
  { date: new Date('2020-11-01'), value: 1.75, category: 'MSCI' },
  { date: new Date('2020-12-01'), value: 1.8, category: 'MSCI' },
].map((v, i, ds) => {
  if (i === 0) v.open = v.open * 0.95
  else v.open = ds[i - 1].close
  v.close = v.value
  v.high = Math.max(v.open, v.close) * 1.05
  v.low = Math.min(v.open, v.close) * 0.95
  delete v.value
  return v
})
// 3D Area = allocation / period x evolution
export const allocationPerSectorEvolution = [
  { date: new Date('2020-01-01'), value: 0.2, category: 'Energy' },
  { date: new Date('2020-02-01'), value: 0.3, category: 'Energy' },
  { date: new Date('2020-03-01'), value: 0.4, category: 'Energy' },
  { date: new Date('2020-04-01'), value: 0.5, category: 'Energy' },
  { date: new Date('2020-05-01'), value: 0.6, category: 'Energy' },
  { date: new Date('2020-06-01'), value: 0.7, category: 'Energy' },
  { date: new Date('2020-07-01'), value: 0.8, category: 'Energy' },
  { date: new Date('2020-08-01'), value: 0.9, category: 'Energy' },
  { date: new Date('2020-09-01'), value: 0.8, category: 'Energy' },
  { date: new Date('2020-10-01'), value: 0.7, category: 'Energy' },
  { date: new Date('2020-11-01'), value: 0.6, category: 'Energy' },
  { date: new Date('2020-12-01'), value: 0.5, category: 'Energy' },
  { date: new Date('2020-01-01'), value: 0.8, category: 'Materials' },
  { date: new Date('2020-02-01'), value: 0.7, category: 'Materials' },
  { date: new Date('2020-03-01'), value: 0.6, category: 'Materials' },
  { date: new Date('2020-04-01'), value: 0.5, category: 'Materials' },
  { date: new Date('2020-05-01'), value: 0.4, category: 'Materials' },
  { date: new Date('2020-06-01'), value: 0.3, category: 'Materials' },
  { date: new Date('2020-07-01'), value: 0.2, category: 'Materials' },
  { date: new Date('2020-08-01'), value: 0.1, category: 'Materials' },
  { date: new Date('2020-09-01'), value: 0.2, category: 'Materials' },
  { date: new Date('2020-10-01'), value: 0.3, category: 'Materials' },
  { date: new Date('2020-11-01'), value: 0.4, category: 'Materials' },
  { date: new Date('2020-12-01'), value: 0.5, category: 'Materials' },
]

// Stack Bar with a "Sum" total bar
//? https://supportcenter.devexpress.com/ticket/details/t996201/stackedbar-chart-how-to-display-the-total-count-at-the-top-of-the-stacked-bar-in-chart
export const stackSumTransformer = options => {
  options.commonSeriesSettings.type = 'stackedbar'
  const x = options.commonSeriesSettings.argumentField
  const y = options.commonSeriesSettings.valueField
  if (options.series.length === 1 && options.dataSource.at(-1)[x] === 'Other') {
    //* Add Total data & Resplit Other
    options.dataSource = options.dataSource.flatMap(v => {
      let groups = v.group
      if (v[x] === 'Other') groups = v.group.flatMap(v => v.group.map(w => ({ ...w, [x]: 'Other', _other: w[x] })))
      const total = { [x]: v[x], _total: v[y] }
      return groups.concat(total)
    })
    //* Add stripe opacity
    const onDrawn = options.onDrawn || (() => null)
    options.onDrawn = e => {
      const rects = µµ('.dxc-series-group rect', e.element)
      const stripe = Object.values(rects.group(v => v.getAttribute(options.rotated ? 'y' : 'x')))
      stripe.forEach(group => group.forEach((el, i) => i % 2 && el.setAttribute('opacity', 0.7)))
      onDrawn(e)
    }
  }
  //* Add total config
  let c = 0
  options.series = options.series
    .map(v => {
      v.label = { visible: false }
      return v
    })
    .concat({
      type: 'rangeBar',
      rangeValue1Field: '_total',
      rangeValue2Field: '_total',
      minBarSize: 3,
      color: '#123456',
      showInLegend: false,
      label: {
        position: 'out',
        customizeText: ({ valueText, value }) => {
          c++
          if (value >= 0 && c % 2) return valueText
          if (value < 0 && (c % 2) - 1) return valueText
        },
      },
    })
  return options
}

export default {
  // 2D Bar = contribution / sector
  Bar: {
    // data: contributionPerSubsector,
    data: contributionPerSector,
    options: {
      title: 'Contribution per sector',
      viz: 'bar',
      horizontal: true,
      x: 'key',
      y: 'value',
      // TODO: implement sorting shortcut per dimension (x, y, z, category), turn into function
      sort: v => -Math.abs(v.value),
      limit: 5,
      formatY: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      formatLabel: v => (v * 10000).format() + 'bp',
      selection: [{ key: 'Real Estate' }, { key: 'Financials' }], // initial selection
      //? Expose some transformer functions via a shortcut label and allow an array of transformers
      transformer: stackSumTransformer,
    },
  },
  // 2D Pie = allocation / sector
  Pie: {
    data: allocationPerSector,
    options: {
      title: 'Allocation per sector',
      viz: 'pie',
      x: 'key',
      y: 'value',
      formatLabel: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      sort: '-value',
      limit: 5,
      // tooltip: true,
      // legend: true,
      // label: true,
    },
  },
  // 2D Line = valuation / period
  Line: {
    data: valuation,
    options: {
      title: 'Valuation History',
      viz: 'line',
      x: 'date',
      y: 'value',
      // formatX: v => v.format('month, year'),
      formatY: v => v.format(4) + '$',
      formatLabel: v => v.format(4) + '$',
    },
  },
  // 2D Dot = volatility / performance
  Dot: {
    data: Array(5)
      .fill(0)
      .map(_ => ({
        volatility: Math.random() * 15,
        performance: (Math.random() - 0.4) * 100,
      })),
    options: {
      title: 'Risk vs Reward',
      viz: 'dot',
      x: 'volatility',
      y: 'performance',
      formatX: v => v.toFixed(2),
      formatY: v => v.toFixed(2),
      formatLabel: v => v.toFixed(2),
    },
  },
  // 3D Pies = allocation / sector AND subsector
  // Pies: {
  //   data: allocationPerSubsector,
  //   options: {
  //     title: 'Allocation per sector and subsector',
  //     viz: 'pie', // 'bar'
  //     x: 'key',
  //     y: 'value',
  //     category: 'category',
  //     sort: '-value',
  //     limit: 5,
  //   },
  // },
  // 3D ClusterBar = contribution / sector x compare
  ClusterBar: {
    data: contributionPerSectorCompare,
    options: {
      title: 'Contribution per sector',
      viz: 'bar',
      x: 'key',
      y: 'value',
      category: 'category',
      // sort: ['category', '-value'],
      sort: v => -Math.abs(v.group[1]?.value) || 0,
      limit: 5,
      formatY: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      formatLabel: v => (v * 10000).format() + 'bp',
    },
  },
  // 3D StackBar = attribution / sector
  StackBar: {
    data: attributionPerSector,
    options: {
      title: 'Attribution per sector',
      viz: 'bar',
      stack: true,
      x: 'key',
      y: 'value',
      category: 'category',
      sort: v => -v.group.reduce((acc, v) => acc + Math.abs(+v.value || 0), 0),
      limit: 5,
      formatY: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      formatLabel: v => (v * 10000).format() + 'bp',
      transformer: stackSumTransformer,
    },
  },
  // 3D Line = valuation / period x compare
  Lines: {
    data: valuationCompare,
    options: {
      title: 'Valuation History',
      viz: 'line',
      x: 'date',
      y: 'close',
      category: 'category',
      rebase: true,
      // label: false,
      formatX: v => v.format('month, year'),
      formatY: v => (v == null ? '' : `${v > 0 ? '+' : ''}${+(v * 100).toPrecision(3)}%`),
      formatLabel: v => (v == null ? '' : `${v > 0 ? '+' : ''}${+(v * 100).toPrecision(3)}%`),
      // formatY: v => v.format(4) + '$',
      // formatLabel: v => v.format(4) + '$',
      transformer: options => {
        delete options.argumentAxis.label.format
        return options
      },
    },
  },
  // 3D Area = allocation / period x evolution
  Area: {
    data: allocationPerSectorEvolution,
    options: {
      title: 'Evolution of allocation per sector',
      viz: 'area',
      x: 'date',
      y: 'value',
      category: 'category',
      stack: true,
      fullstack: true,
      formatX: v => v.format('month, year'),
      formatY: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      formatLabel: v => (v == null ? '' : +(v * 100).toPrecision(3) + '%'),
      transformer: options => {
        delete options.argumentAxis.label.format
        return options
      },
    },
  },
  // 4D Bubble = volatility / performance / allocation / category
  Bubble: {
    data: Array(50)
      .fill(0)
      .map(_ => ({
        Volatility: Math.random() * 15,
        Performance: (Math.random() - 0.4) * 10,
        Allocation: Math.random() * 100,
        'Asset Class': ['Equity', 'Fixed Income', 'Alternatives', 'Cash'][Math.floor(Math.random() * 4)],
      })),
    options: {
      title: 'Risk vs Reward',
      viz: 'dot',
      x: 'Volatility',
      y: 'Performance',
      z: 'Allocation',
      category: 'Asset Class',
      formatX: v => v.toFixed(2) + '%',
      formatY: v => v.toFixed(2) + '%',
      formatZ: v => v.toFixed(2) + '%',
      formatLabel: v => v.toFixed(2) + '%',
    },
  },
  // 3D Sunburst = allocation / sector AND subsector
  Sunburst: {
    data: allocationPerSubsector,
    options: {
      title: 'Allocation per sector and subsector',
      viz: 'sunburst',
      x: 'key',
      y: 'value',
      category: 'category',
      sort: '-value',
      limit: 5,
    },
  },
  Box: {
    data: valuationCompare.filter(v => v.category === 'GRK Sport'),
    options: {
      viz: 'box',
      x: 'date',
      // category: 'category',
    },
  },
  Radar: {
    data: contributionPerSectorCompare,
    options: {
      viz: 'radar',
      x: 'key',
      y: 'value',
      category: 'category',
    },
  },
  // Waterfall: {
  //   data: cash,
  //   options: {
  //     viz: 'radar',
  //     x: 'key',
  //     y: 'value',
  //     category: 'category',
  //   },
  // },
}
