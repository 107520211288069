import { RestService } from './RestService'
import { Image } from './ImageService'

export type ImageFolder = {
  id: number
  name: string
  specialtyName: string
  variables: string[]
  images: Image[]
}

export class ImageFolderService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'image-folder'
  }

  async getById({ id }: { id: number }): Promise<ImageFolder> {
    return this.get(`${this.baseRoute}/${id}`)
  }

  async findManyBySpecialtyName({ specialtyName }: { specialtyName: string }): Promise<ImageFolder[]> {
    return this.get(`${this.baseRoute}/by-specialty-name`, {
      params: {
        specialtyName,
      },
    })
  }

  async create({ imageFolderInput }: { imageFolderInput: any }): Promise<ImageFolder> {
    return this.post(`${this.baseRoute}/create`, {
      imageFolderInput,
    })
  }

  async deleteOne({ id }: { id: number }): Promise<ImageFolder> {
    return this.delete(`${this.baseRoute}/${id}`)
  }
}

const imageFolderService = new ImageFolderService()
export default imageFolderService
