import { Stylesheet } from '../applications/builder/builder'
import { RestService } from './RestService'

export class StylesheetService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'stylesheet'
  }

  async findMany(): Promise<Stylesheet[]> {
    return this.get(`${this.baseRoute}`)
  }

  async findManyByName({ name, limit = 1 }: { name: string; limit?: number }): Promise<Stylesheet[]> {
    return this.get(`${this.baseRoute}/by-name`, {
      params: { name, limit },
    })
  }

  async create({ stylesheetInput }: { stylesheetInput: any }): Promise<Stylesheet> {
    return this.post(`${this.baseRoute}/create`, {
      stylesheetInput,
    })
  }

  async deleteOne({ id }: { id: number }): Promise<Stylesheet> {
    return this.delete(`${this.baseRoute}/${id}`)
  }

  async deleteManyByName({ name, limit = 1 }: { name: string; limit?: number }): Promise<Stylesheet[]> {
    return this.delete(`${this.baseRoute}`, {
      params: { name, limit },
    })
  }
}

const stylesheetService = new StylesheetService()
export default stylesheetService
