<style scoped>
h1 .search-filters {
  display: flex;
  flex: 1;
  margin-left: 10px;
}
h1 .search-filters .autocomplete {
  min-width: 90% !important;
}
.cell-details .resizer {
  display: none;
}
.cell-details {
  max-width: 60px;
}
.cell-details > div {
  cursor: pointer;
  position: relative;
  padding: 0;
  min-width: 59px;
  line-height: 28px;
  overflow: visible;
  background: inherit;
}
.cell-details > div > .details {
  width: 100%;
  color: var(--primary);
  text-align: center;
  text-decoration: underline;
}
.cell-details > div > .tooltip {
  display: none;
  z-index: 1;
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 60px;
  box-shadow: inset 0 0 0 0.5px var(--background);
  background: inherit;
  width: max-content;
}
.cell-details > div:hover > .tooltip {
  display: flex;
  align-items: center;
  justify-content: center;
  font: var(--p2);
}
.tooltip .row {
  padding: 0 10px;
}
.tooltip a {
  display: flex;
  align-items: center;
}
.tooltip img {
  width: 30px;
}
.tooltip .ghost {
  width: 30px;
  height: 30px;
  padding: 8px;
}
.tooltip .task {
  height: 8px;
  width: 30px;
  margin: 0 1px;
}
.tooltip .column .task {
  margin-top: 2px;
}
.tag {
  display: flex;
  width: 20px;
  height: 20px;
  margin: auto;
  padding: 4px !important;
  align-items: center;
  font-size: 10px;
  font-weight: bold;
  font-variant-numeric: tabular-nums;
}
:deep() .cell > * {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: fit-content;
}

.cell-steps .tasks div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.cell-owner .owner,
.cell-assignee .owner {
  width: 25px;
  height: 25px;
  margin: auto;
  padding: 0;
}
.file_icon {
  min-width: 28px;
}
.modal {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.block > .row:first-child .ghost {
  flex-shrink: 0;
  width: 40px;
  height: 40px;
  padding: 12px;
}
h1 .autocomplete {
  margin-left: 5px;
}
h1 {
  display: flex;
  /* justify-content: space-between; */
}
h1 .year {
  width: 120px;
  font-size: 14px;
  margin-right: 16px;
}

.compact-loading {
  padding: 14px;
  text-align: center;
}
.compact-loading .spinner {
  position: relative;
  background-color: transparent;
  min-width: 0;
  margin-right: 8px;
}
.compact-loading img {
  display: none;
}

.icon-button {
  height: 40px;
  width: 40px;
  padding: 12px;
}

.container-body {
  margin-top: 38px;
}

.container-body .tab-row {
  position: absolute;
  top: -39px;
  min-width: 75%;
}

.container-body .tab-row .tab {
  border-bottom: 1px solid var(--colors-gray1);
  margin-right: 5px !important;
  cursor: pointer;
  text-align: center;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  max-height: 40px;
  height: 40px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
  color: var(--colors-inactive);
}

.container-body .tab-row .selected-tab {
  border-bottom: 1px solid var(--colors-primary-default);
  color: var(--color-text);
}
</style>

<template lang="pug">
transition(@leave='leave')
  loader(v-if="isLoading")
h1
  span {{ t[$root.screen.path] }}
  .search-filters
    select.year(v-model="year" style="width: 120px; font-size: 14px;")
      option(v-for="m in years" :value="m" :key="m") {{m}}
    autocomplete.right(
      :data="autocomplete_data"
      :options="{ placeholder: t.search }"
      :modelValue="activated_filters"
      @update:modelValue="autocomplete_input")
subtitle(style="font-style: italic; margin-top: -5px;") {{ t['client-edition-production-subtitle'] }}
quick-filters

.block.expand.container-body
  .row.center.left.tab-row
    .block.tab(role="tab" v-for="specialtyName in Object.keys(productionBySpecialty)" :key="specialtyName" @click="()=>changeSpecialty(specialtyName)" :class="{'selected-tab': specialtySelected ? specialtySelected === specialtyName : specialtyName === 'undefined'}") {{specialtyName === 'undefined' ? t.productionList.specialtyTab.oldPress : t.productionList.specialtyTab.specialtyName || specialtyName}}
  .row.center.left
    popup(:show="confirmRemove")
      template(v-slot:header)
        | {{t["confirmation_remove"]}}
      template(v-slot:content)
        | {{t["text_remove"]}}
      template(v-slot:action)
        button.secondary-action(@click="confirmRemove = false") {{t['confirmation_btn_close']}}
        button.main-action(@click="deleteRuns()") {{t["confirmation_btn_delete"]}}
    template(v-if="selected.length")
      autocomplete(style="width:400px" :data="emails" :options="{ placeholder: $root?.profile?.email }" v-model="assignee" v-if="emails.length")
      button.ghost.icon-button(@click="assign_runs(selected, assignee || $root?.profile?.email)" tt="Assignate")
        svg-icon(name="ic_user")
      button.ghost.icon-button(@click="exported_production.dlCSV('fr', 'production.csv')" tt="Export")
        svg-icon(name="pt-icon-export")
      button.ghost.icon-button(@click="showConfirmRemove" tt="Remove")
        svg-icon(name="pt-icon-trash")
      button.ghost.icon-button(
        tt="validate"
        @click.stop="validate_selected"
      )
        svg-icon(class="icon" style="fill: var(--positive);" name="pt-icon-tick")
      button.ghost.icon-button(tt="rerun" @click.stop="rerunSelected()")
        svg-icon(class="icon" style="fill: var(--cat10);" name="pt-icon-play")

    select(@change="update_query({ scheduled: $event.target.value })")
      option(value="") {{t.productionList.selectRun.last}}
      option(value="all") {{t.productionList.selectRun.all}}

    .block.compact-loading(v-if="!isLoading && !prodLoadedAll")
      .row.right
        transition(@leave='leave')
          loader
        span {{t.productionList.loadingMore}}

  spreadsheet.stripped(:data="filtered_production" :options="spreadsheetOptions" :key="spreadsheetOptions.key")
    template(v-slot:header-check)
      input(type="checkbox" :checked="selected.length === filtered_production.length" v-indeterminate="selected.length > 0 && selected.length !== filtered_production.length" @click="selected = selected.length === filtered_production.length ? [] : filtered_production.map('id')" @mousedown.prevent.stop="")
    template(v-slot:header-details)
      div(style="cursor: pointer" @mousedown.prevent.stop="")

    template(v-slot:cell-id="s")
      .tag {{ s.line.id }}

    template(v-slot:cell-check="s")
      input(type="checkbox" :checked="selected.includes(s.line.id)" @click.stop="selected = selected.map().toggle(s.line.id)" @mousedown.prevent.stop="")

    template(v-slot:cell-details="s")
      div(style="overflow: unset")
        router-link.details(:to="s.line.link") {{ t.details }}
        .tooltip
          template(v-if="!s.line.isParallel")
            template(v-if="s.line.isValidation")
              button.ghost(:tt="s.line.last_step.type === 'user_input' ? t.submit : t.accept" @click.stop="validate_step(s.line.id, s.line.log.id)")
                svg-icon(class="icon" style="fill: var(--positive);" name="pt-icon-tick")
              button.ghost(:tt="t.reject" @click.stop="set(['data', 'runs', s.line.id, 'logs', s.line.log.id, 'status'].join('.'), 'error')")
                svg-icon(class="icon" style="fill: var(--negative);" name="pt-icon-cross")
            template(v-else)
              button.ghost(:tt="t.skip" @click.stop="skip_step(s.line.id, s.line.log.id)")
                svg-icon(class="icon" style="fill: var(--yellow1);" name="ic_redo")
              button.ghost(:tt="t.rerun" @click.stop="rerun_step(s.line, s.line.log.action_id)" v-if="s.line.last_step.type !== 'wait_for'")
                svg-icon(class="icon" style="fill: var(--cat10);" name="pt-icon-play")
          a(download="" target="_blank" :href="getUrlForPdf(document)" :tt="document.alias" v-for="document in documentsByRun[s.line.id]")
            ui-asset.file_icon(:name="'icon_' + s.line.format.lower()")
          .row.center(v-if="s.line.workflow")
            //.task( :class="[s.line.logs && s.line.logs.v().filter(l => l && l.action_id === action.id).map('status').last(), { skipped: s.line.logs && s.line.logs.v().filter(l => l && l.action_id === action.id).map('skipped').last() && s.line.logs.v().filter(l => l && l.action_id === action.id).map('skipped').last() !== 'rerun'}]" v-for="action in s.line.workflow.actions.v().filter()" :tt="action.name" @click="$router.push({ path: '/workflow', query: Object.assign({}, $root.query, { id: s.line.sid.split('|')[0], rid: s.line.sid.split('|')[1], action_id: action.id }) })")
            .task( :class="[task.status, { skipped: task.skipped }]" v-for="task in s.line.tasks" :tt="task.name" @click="$router.push({ path: '/workflow', query: Object.assign({}, $root.query, { id: s.line.sid.split('|')[0], rid: s.line.sid.split('|')[1], action_id: task.id }) })")
    template(v-slot:cell-assignee="s")
      .owner(v-if="s.line.context.assignee" :style="{ background: 'var(--cat' + ((s.line.context.assignee || '').charCodeAt(0) % 10 + 1) + ')' }" :tt="s.line.context.assignee.split('@')[0].titleize()") {{ s.line.context.assignee.split('@')[0].split('.').map('0').join('').upper() }}
    template(v-slot:cell-template="s")
      div(v-if="s.line.template && s.line.tid !== null") {{ s.line.template }}
      div(v-else style="color: red;") {{t.productionList.spreadsheet.templateRemoved}}
    template(v-slot:cell-language="s")
      div {{ s.line.lang }}
      //.flag_box(:tt="s.line.language.upper()")
      //  svg-icon(class="icon" :name="'flag-' + s.line.language.slice(0,2).lower()")
    template(v-slot:cell-jurisdiction="s")
      div {{ s.line.jurisdiction }}
      //.flag_box(:tt="s.line.language.upper()")
      //  svg-icon(class="icon" :name="'flag-' + s.line.language.slice(-2).lower()")
    template(v-slot:header-start-date)
      div {{ t.launched }}
    template(v-slot:header-domain)
      div {{ t.period }}
    //- template(v-slot:cell-domain="s")
    //-   div {{ new Date(s.line.domain).format('YYYY-MM') }}
    template(v-slot:cell-last-update-date="s")
      div {{ new Date(s.line.last_update_date).format('YYYY-MM-DD hh:mm:ss') }}
    template(v-slot:cell-start-date="s")
      div {{ new Date(s.line.context.run_time).format('YYYY-MM-DD') }}
    template(v-slot:cell-format="s")
      .tt(style="display: flex;" :tt="s.line.format.upper()")
        ui-asset.file_icon(:name="'icon_' + s.line.format.lower()")
    template(v-slot:cell-workflow="s")
      div {{ (s.line.workflow || {}).name }}
    template(v-slot:cell-steps="s")
      .row.tasks
        div {{ s.line.log.action_id +  " / " + (Array.isArray(s.line.workflow.actions) ? s.line.workflow.actions.length - 1 : Object.keys(s.line.workflow.actions).length) }}
          .task(:class="[s.line.log.status, { skipped: s.line.log.skipped && s.line.log.status === 'killed'}]")
</template>

<script>
import { computed, ref, watch } from 'vue'
import config from '../../../config'
import { useImpressions } from '../composables/useImpressions'
import { useProduction } from '../composables/useProduction'
import { useUsers } from '../composables/useUsers'
import { useProgress } from '../composables/useProgress'
import Popup from '../../../components/ui/popup.vue'
import { commandrSDKFileSystem } from '../../../features/commandr'
import excelTemplateService from '@100-m/hauru/src/services/ExcelTemplateService'

export const additions = { icon: 'ic_history' }
export default {
  components: { Popup },
  setup() {
    const {
      production,
      deleteRun,
      deleteRuns,
      loaded: prodLoaded,
      loadedAll: prodLoadedAll,
      years,
      year,
    } = useProduction()
    const { impressions, loaded: impressionsLoaded } = useImpressions()
    const { emails, loaded: usersLoaded } = useUsers()
    const { progress } = useProgress([prodLoaded, impressionsLoaded, usersLoaded])

    // Initial loading
    const isLoading = computed(() => progress.value !== 1)

    const productionBySpecialty = ref({})
    const specialtySelected = ref()
    const groupProductionBySpecialty = () => {
      productionBySpecialty.value = production.value.group('press.specialtyName')
      if (!specialtySelected.value) {
        const firstSpecialtyGroupName = Object.keys(productionBySpecialty.value)[0]
        specialtySelected.value = firstSpecialtyGroupName === 'undefined' ? undefined : firstSpecialtyGroupName
      }
    }
    watch(production, groupProductionBySpecialty)

    return {
      deleteRun,
      deleteMany: deleteRuns,
      impressions,
      production,
      emails,
      isLoading,
      prodLoadedAll,
      years,
      year,
      productionBySpecialty,
      specialtySelected,
    }
  },
  data() {
    return {
      selected: [],
      commandr: config.commandr,
      confirmRemove: false,
      filtered_timelines: ['future_task'],
      assignee: null,
      hasExcelTemplatesFeature: config.excelTemplatesFeature,
    }
  },
  async mounted() {
    if (this.hasExcelTemplatesFeature) {
      // Fetch Excel API runs and display them in the console only, available for customer support
      try {
        const excelRuns = await excelTemplateService.getRuns()
        console.log('Excel API runs:', excelRuns)
      } catch (error) {
        console.log('Error when fetching Excel API runs', error)
      }
    }
  },
  methods: {
    async rerunSelected() {
      await Promise.all(
        this.filtered_production
          .filter(r => this.selected.includes(r.id))
          .map(r => this.rerun_step(r, r.logs.v().last().action_id)),
      )
      this.selected = []
    },
    showConfirmRemove() {
      this.confirmRemove = true
    },
    isParallel(s) {
      if (s.line.id === undefined || !s.line.logs) return false
      const action = s.line.workflow.actions[s.line.log.action_id]
      return action !== undefined && action.parallel
    },
    deleteRuns() {
      this.deleteMany(this.selected)
      this.confirmRemove = false
    },
    validate_selected() {
      const resolveSelectedRuns = this.filtered_production.filter(r => this.selected.includes(r.id))
      resolveSelectedRuns.forEach(run => {
        const logEntries = Object.values(run.logs).sort((a, b) => a.id - b.id)
        const lastLog = logEntries[logEntries.length - 1]

        if (lastLog.status === 'running') {
          const { action_id } = lastLog
          const workflowAction = run.workflow.actions[action_id]
          const { alias, type } = workflowAction
          const isValidationStep = alias === 'prompt' || type === 'user_validation'

          if (isValidationStep) {
            const lid = lastLog.id
            const rid = run.id
            this.validate_step(rid, lid)
          }
        }
      })
    },
    autocomplete_input(event) {
      const event_array = event.map(v => [v.slice(0, v.indexOf('.')), v.slice(v.indexOf('.') + 1, v.length)])
      const grouped_events = event_array.group('0')
      const selected_filters = grouped_events.map(g => g.map('1').join('|'))
      const new_filter_selected = this.$route.query.filter((v, k) => !this.$route.query.keys().includes(k))
      const other = { year: this.$route.query.year }
      const query = { ...selected_filters, ...new_filter_selected, ...other }
      this.$router.push({ query })
    },
    format_filter_data(name, value) {
      return name + '.' + value
    },
    getUrlForPdf(document) {
      if (['docker', 'aws'].includes(import.meta.env.VITE_COMMANDR_FILESYSTEM_MODE)) {
        console.log(commandrSDKFileSystem.getPublicUrl({ filenameWithPath: document.alias }))
        return commandrSDKFileSystem.getPublicUrl({ filenameWithPath: document.alias })
      }
      return `${import.meta.env.VITE_COMMANDR}/${document.alias}`
    },
    changeSpecialty(specialtyName) {
      this.specialtySelected = specialtyName === 'undefined' ? undefined : specialtyName
      this.selected = []
    },
  },
  computed: {
    impressionByRuns() {
      return this.impressions.reduce((acc, b) => {
        if (acc[b.run_id]) acc[b.run_id].push(b)
        else acc[b.run_id] = [b]
        return acc
      }, {})
    },
    documentsByRun() {
      return Object.fromEntries(
        Object.entries(this.impressionByRuns).map(([run_id, imps]) => [
          run_id,
          imps
            .group('name')
            .v()
            .sort(d => +d.first().path.split('-')[1])
            .last(),
        ]),
      )
    },
    filtered_production() {
      const production = this.production_schedule

      const filters = Object.entries($root.query)
        .filter(([k, v]) => !['search', 'selected', 'scheduled', 'year'].includes(k))
        .map(([k, v]) => [k, v.split('|')])
      return production.filter(d =>
        filters.every(
          ([k, vs]) =>
            d[k] &&
            vs.some(v => {
              const value = `${d[k]}`.replace('.', '')
              if (/^>/.test(v)) {
                return d[k] > v.slice(1)
              } else if (/^</.test(v)) {
                return d[k] < v.slice(1)
              } else if (/~/g.test(v) && d[k].split('|').length === 2) {
                const [dkStart, dkEnd] = d[k].split('|')
                const [start, end] = v.split('~')
                return dkStart === start && dkEnd === end
              } else {
                return value === v
              }
            }),
        ),
      )
    },
    production_schedule() {
      let production = this.production.v().filter(run => {
        return this.specialtySelected ? run.press.specialtyName === this.specialtySelected : !run.press.specialtyName
      })

      if (!$root.query.scheduled)
        production = production
          .group(d =>
            [
              'template',
              this.specialtySelected ? (this.specialtySelected === 'document by fund' ? 'shareId' : '') : 'isin',
              'language',
              'domain',
              'workflow',
            ]
              .map(c => (typeof d[c] === 'object' ? ['wid', d[c].id].join('-') : d[c]))
              .join('-'),
          )
          .map(v => v.last())
          .v()
      if ($root.query.scheduled === 'assignee')
        production = production.filter(r => r.workflow.actions[r.logs.last().action_id].user === $root?.profile?.email)
      if ($root.query.scheduled === 'owner')
        production = production.filter(r => r.context.owner === $root?.profile?.email)
      if ($root.query.scheduled === 'year')
        production = production.filter(r => r.start_date >= new Date().minus('1 year').format())
      if ($root.query.scheduled === 'active')
        production = production.filter(
          r => !(r.workflow.actions.last().id === r.logs.last().action_id && r.logs.last().status === 'success'),
        )
      return production
    },
    selected_production() {
      return this.production.filter(d => this.selected.includes(d.id))
    },
    exported_production() {
      return this.selected_production.map(v => this.exported_columns.reduce((acc, k) => ((acc[k] = v[k]), acc), {}))
    },
    activated_filters() {
      const query_params = this.$route.query
      const active_filters = query_params.map(filter_value => filter_value.split('|'))
      const filters = []
      Object.entries(active_filters).forEach(([filter_name, filter_values]) => {
        if (filter_name === 'year') return
        filter_values.forEach(value => {
          filters.push(this.format_filter_data(filter_name, value))
        })
      })
      return filters
    },
    autocomplete_data() {
      const column_selections = this.production_metadata.columns
        .filter(k => {
          return k && !['assignee', 'start_date', 'last_update_date'].includes(k)
        })
        .concat(['status', 'workflow_name', 'step'])
      const data = this.production.reduce((acc, v) => {
        column_selections.forEach(k => {
          if (typeof v[k] === 'string') {
            const filter_criteria = v[k].replace('.', '')
            acc[k] = acc[k] || {}
            acc[k][filter_criteria] = filter_criteria
          }
        })
        return acc
      }, {})
      return data
    },
    spreadsheetOptions() {
      if (this.specialtySelected) {
        let specialtyColumns = []

        if (this.specialtySelected === 'document by share class') {
          specialtyColumns = ['fundId', 'shareId']
        } else if (this.specialtySelected === 'document by fund') {
          specialtyColumns = ['fundId']
        }

        return {
          key: `slash-${this.specialtySelected}`,
          editable: false,
          sort: ['-id'],
          columns: [
            {
              name: 'check',
              freeze: true,
            },
            {
              name: 'details',
              freeze: true,
            },
            'format',
            'id',
            'owner',
            'template',
            'workflow',
            ...specialtyColumns,
            'patterns_name',
            'domain',
            'start_date',
            'last_update_date',
            'language',
            'jurisdiction',
            'steps',
          ],
        }
      } else {
        return this.production_metadata
      }
    },
  },
}
</script>
