export function getHeaderData(context: any, headerDataPath = 'reporting.header') {
  const headerData = headerDataPath.split('.').reduce((acc: any, key: string) => acc?.[key], context.data)
  if (!headerData) {
    throw new Error(`Could not find headerData in data report at path: ${headerDataPath}`)
  }
  return headerData
}

// TODO: get the logo images from stylesheet
export function getLogoImg(context: any) {
  return window.config.logo
}

export function getShareMFundCode(context: any) {
  return context.data?.referential?.fundCharacteristics?.otherFundCharacteristics?.share_mfund_code
}

export function getShareApirCode(context: any) {
  return context.data?.referential?.fundCharacteristics?.otherFundCharacteristics?.share_apir_code
}

export function getShareReportingPortfolio(context: any) {
  return context.data?.referential?.fundCharacteristics?.otherFundCharacteristics?.reporting_portfolio
}
