import { ResolvedBuilderOptions } from '../composables/builderOptions'
import { format } from 'date-fns'

function getCurrency(unit?: string, shareCurrency?: string) {
  if (!unit) return
  if (unit === 'shareCurrency') return shareCurrency
  if (['$', '€', '£'].includes(unit)) return unit.replace('$', 'USD').replace('€', 'EUR').replace('£', 'GBP')
  if (unit.length === 3) return unit
}
export function formatFactory({
  unit,
  digit,
  lang = 'en',
  postFormat = null,
  shareCurrency,
}: Partial<ResolvedBuilderOptions>) {
  if (!unit && (digit === null || digit === undefined || digit === '')) return (num: number) => num?.toString() || num
  const formatFn = (num: number) => {
    if ((!num && num !== 0) || typeof num !== 'number') return num ? '' + num : ''
    digit = Math.max(0, Math.min(digit || 0, 4))
    if (unit === 'bp') return Math.round(num * 10000) + 'bp'
    if (unit === '%')
      return (
        Intl.NumberFormat(lang.slice(0, 2), {
          notation: 'compact',
          minimumFractionDigits: digit,
          maximumFractionDigits: digit,
        }).format(num * 100) + '%'
      )
    const _currency = getCurrency(unit, shareCurrency)
    if (_currency) {
      return Intl.NumberFormat(lang.slice(0, 2), {
        style: 'currency',
        currency: _currency,
        notation: 'compact',
        minimumFractionDigits: digit,
        maximumFractionDigits: digit,
      }).format(num)
    }
    return Intl.NumberFormat(lang.slice(0, 2), {
      notation: 'compact',
      minimumFractionDigits: digit,
      maximumFractionDigits: digit,
    }).format(num)
  }
  if (!postFormat) return formatFn
  return (num: number | string) => {
    // Dont post format / postformat strings
    if (typeof num === 'string') return num
    return postFormat({ formattedValue: formatFn(num), value: num, unit, digit })
  }
}

export function dateFormatFactory(
  dateFormat: 'custom' | Intl.DateTimeFormatOptions,
  customDateFormat?: string,
  lang: string = 'en',
) {
  const _lang = lang?.replace('_', '-') || 'en'
  if (!dateFormat) return (date: Date) => date.toLocaleDateString(_lang)
  if (dateFormat === 'custom') return (date: Date) => format(date, customDateFormat || 'yyyy-MM-dd')
  return (date: Date) => Intl.DateTimeFormat(_lang, dateFormat).format(date)
}
