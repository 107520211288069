import { computed } from 'vue'

import { ResolvedBuilderOptions } from './builderOptions'
import { getHeaderData } from '../lib/header'
// TODO: have to put this override here else type checks fail
interface BuilderBlockProps {
  data?: any[] | Record<string, any[]>
  options: ResolvedBuilderOptions
  context: Record<string, any>
}

export default function useTranslations(props: BuilderBlockProps) {
  const headerData = computed(() => {
    try {
      return getHeaderData(props.context)
    } catch (e) {
      return {}
    }
  })
  const dynamicTranslations: Record<string, string> = {
    fundName_dynamic: 'fundName',
    shareName_dynamic: 'share_name',
    benchmarkName_dynamic: 'benchmarkName',
  }

  function _translate(key: string) {
    if (dynamicTranslations[key]) {
      return headerData.value[dynamicTranslations[key]]
    }
    return props.context.translations[key] || $root.t[key] || key
  }

  function getKeyFootnoteIndex(key: string, footnotes: any[]) {
    return footnotes.reduce((acc, d, index) => {
      if (d.key !== key) return acc
      if (!acc) {
        return `${index + 1}`
      }
      return `${acc},${index + 1}`
    }, '')
  }
  function translateFootnotes(footnotes: any[]) {
    if (!footnotes || !footnotes.filter(d => d.key).length) return []
    return footnotes.map(footnote => {
      const value = footnote.value.replace(/^translation\./, '')
      const key = footnote.key.replace(/^translation\./, '')
      return {
        ...footnote,
        key,
        value: _translate(value),
        valueKey: value,
      }
    })
  }
  const allFootnotes = computed(() => {
    if (props.context?.globalFootnotes && props.context?.globalFootnotes.length) {
      return translateFootnotes(
        props.context.globalFootnotes.map(d => ({ ...d, scope: 'global' })),
        props.context.translations,
      )
    }
    if (props.context?.pageFootnotes && props.context?.pageFootnotes.length) {
      return translateFootnotes(
        props.context.pageFootnotes.map(d => ({ ...d, scope: 'page' })),
        props.context.translations,
      )
    }
    if (props.context?.footnotes && props.context?.footnotes.length) {
      return translateFootnotes(
        props.context.footnotes.map(d => ({ ...d, scope: 'block' })),
        props.context.translations,
      )
    }
    return []
  })
  function parseFootnotes(footnotes: any[]) {
    // If there are multiple footnotes with the same value, we merge them
    const footnoteMap = footnotes?.reduce((acc, footnote, index) => {
      const valueKey = footnote.valueKey
      if (acc[valueKey]) {
        acc[valueKey] = {
          ...acc[valueKey],
          index: `${acc[valueKey].index},${index + 1}`,
        }
      } else {
        acc[valueKey] = {
          ...footnote,
          index: index + 1,
        }
      }
      return acc
    }, {})
    return Object.values(footnoteMap)
  }

  const blockFootnotes = computed(() => {
    return parseFootnotes(Object.values(allFootnotes.value).filter(d => d.scope === 'block'))
  })
  const pageFootnotes = computed(() => {
    return parseFootnotes(Object.values(allFootnotes.value).filter(d => d.scope === 'page'))
  })
  const globalFootnotes = computed(() => {
    return parseFootnotes(Object.values(allFootnotes.value).filter(d => d.scope === 'global'))
  })
  const translate = computed(() => {
    return (value?: string | number) => {
      if (!value) return
      if (typeof value === 'number') return value
      // if (!value.startsWith('translation.')) return value
      const footnotes = allFootnotes.value
      const key = value.replace(/^translation\./, '')
      const translatedKey = _translate(key)
      // debugger
      if (footnotes && footnotes.find(d => d.key === key)) {
        const index = getKeyFootnoteIndex(key, footnotes)
        return `<span>${translatedKey}</span><sup>${index}</sup>`
      }
      return translatedKey
    }
  })

  const translateData = computed(() => {
    return (data: any) => {
      if (!Array.isArray(data)) return data
      if (Array.isArray(data[0])) {
        return data.map(row => {
          return row.map(cell => {
            return typeof cell === 'string' ? translate.value(cell) : cell
          })
        })
      }
      const stringValues = Object.keys(data[0]).filter(key => {
        return typeof data[0][key] === 'string'
      })
      return data.map(row => {
        return Object.fromEntries(
          Object.entries(row).map(([k, v]) => {
            const value = stringValues.includes(k) ? translate.value(v) : v
            return [k, value]
          }),
        )
      })
    }
  })

  return {
    translate,
    translateData,
    blockFootnotes,
    pageFootnotes,
    globalFootnotes,
  }
}
