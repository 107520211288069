<template>
  <!-- :class="{ '!table-auto': _props._options.auto }" -->
  <builder-block v-bind="{ data, options, context }" :class="context.isLast ? 'overflow-hidden' : ''" ref="block">
    <!-- <div>Overflow {{ isOverflow }} {{ end }}</div> -->
    <builderinternals-table
      v-if="formattedData"
      :data="formattedData"
      :columns="tableColumns"
      :start="options?.start || 0"
      :end="end"
      :indent-levels="options?.display === 'compact'"
      ref="tableRef"
    ></builderinternals-table>
  </builder-block>
</template>
<script setup lang="ts">
import { computed, ref, onMounted, nextTick } from 'vue'
import { BuilderOptions, BuilderOptionArgs } from '../composables/builderOptions'
import { formatFactory } from '../lib/format'
import { transformTable } from '../lib/table'
import { table } from './stories'
import useOverflow, { computeTableMaxRows } from '../composables/overflow'

import useTranslations from '../composables/translations'

const props = defineProps(['data', 'options', 'context'])
const emit = defineEmits(['overflow'])

const { translate } = useTranslations(props)
const block = ref()
const tableRef = ref()
const element = computed(() => tableRef.value?.element)
const container = computed(() => {
  return block.value?.container as HTMLElement
})
const end = ref<number | null>(null)

const { isOverflow, initOverflow } = useOverflow(element, container, onOverflow)
function onOverflow() {
  if (!tableData.value?.data?.length) return
  if (!isOverflow.value) return
  const maxRows = computeTableMaxRows(container.value.clientHeight, element.value)
  end.value = (props?.options?.start || 0) + maxRows
  emit('overflow', { isOverflow: false, end: end.value })
  // console.log('Calling setEnd from overflow event', props.options.start)
  // window.requestAnimationFrame(setEnd)
}
onMounted(() => {
  initOverflow()
})

const mode = computed(() => {
  if (!props.data) return
  return getTableMode(props.data)
})
const columns = computed(() => {
  if (mode.value === 'pivot') {
    const _columns =
      props.options.display === 'compact'
        ? ['label'].concat(props.data.columns)
        : props.data.labels.concat(props.data.columns)
    if (!props.options.columns) return _columns
    return _columns
      .filter(col => props.options.columns.includes(col.field || col))
      .sort((a, b) => {
        return props.options.columns.indexOf(a.field || a) - props.options.columns.indexOf(b.field || b)
      })
  }
  return props.options.columns
})
const tableData = computed<{ data: any; columns: any } | null>(() => {
  if (!props.data) return
  if (mode.value === 'table') return props.data
  if (mode.value === 'pivot') {
    return transformTable(props.data, { ...props.options, columns: columns.value })
  }
  if (!props.data.length) return
  if (mode.value === 'array') {
    const columns = props.options.columns || props.data[0]
    const data = props.data.slice(1).map(row =>
      columns.reduce((acc, col, index) => {
        acc[col] = row[index]
        return acc
      }, {}),
    )
    if (props.options.showTotal) {
      const total = columns.reduce((acc, col, colIdx) => {
        if (colIdx === 0) {
          acc[col] = translate.value('total')
        }
        if (typeof data[0][col] === 'number') {
          acc[col] = data.reduce((acc, row) => acc + row[col], 0)
        }
        return acc
      }, {})
      data.push(total)
    }
    return {
      columns,
      data,
    }
  }
  if (mode.value === 'object') {
    const columns = props.options.columns || Object.keys(props.data[0])
    let data = props.data
    if (props.options.showTotal) {
      const total = columns.reduce((acc, col, colIdx) => {
        if (colIdx === 0) {
          acc[col] = translate.value('total')
        }
        if (typeof data[0][col] === 'number') {
          acc[col] = data.reduce((acc, row) => acc + row[col], 0)
        }
        return acc
      }, {})
      data = data.concat(total)
    }
    return {
      columns,
      data,
    }
  }
  return null
})

function applyStriping(data, striping) {
  if (!data) return
  if (!striping) return data
  if (striping === 'odd') {
    return data.map((row, index) => ({ ...row, _striped: index % 2 === 0 }))
  }
  if (striping === 'even') {
    return data.map((row, index) => ({ ...row, _striped: index % 2 !== 0 }))
  }
  return data
}
const formattedData = computed(() => {
  if (!tableData.value) return
  const stripedData = applyStriping(tableData.value.data, props.options.striping)
  return stripedData.map((row, index) => {
    return Object.fromEntries(
      Object.entries(row).map(([key, value]) => {
        if (['_striped', '_level'].includes(key)) return [key, value]
        if (value === null) {
          return [key, '-']
        } else if (typeof value === 'number') {
          return [key, formatFn.value(value, key, row)]
        } else if (typeof value === 'string') {
          return [key, translate.value(value)]
        } else if (typeof value === 'object') {
          return [key, Object.fromEntries(Object.entries(value).map(([k, v]) => [k, formatFn.value(v, key, row)]))]
        } else {
          return [key, value]
        }
      }),
    )
  })
})
// Translate columns and replace the first one if there is a table name
const tableColumns = computed(() => {
  if (!tableData.value) return
  return tableData.value.columns.map((col, idx) => {
    if (idx === 0 && props.options.tableName) {
      return { field: col, name: translate.value(props.options.tableName) }
    }
    if (typeof col === 'string') return { field: col, name: translate.value(col) }
    return {
      name: translate.value(col.field),
      ...col,
    }
  })
})
const formatFn = computed(() => {
  const formatMode = props.options?.formatTable?.mode
  const lang = props.context.variables.lang
  if (formatMode === 'table') {
    // const _formatFn =
    return formatFactory({ ...props.options.formatTable, lang, postFormat: props.context.postFormat })
  } else if (formatMode === 'columns' && props.options.formatTable.formats) {
    const formats = Object.fromEntries(
      Object.entries(props.options.formatTable.formats || {}).map(([col, formatData]) => {
        // console.log('col', col, 'formatData', formatData)
        return [col, formatFactory({ ...formatData, lang, postFormat: props.context.postFormat })]
      }),
    )
    return (v: number, col: string) => {
      return formats[col] ? formats[col](v) : v
    }
  } else if (formatMode === 'rows' && props.options.formatTable.formats) {
    const formats = Object.fromEntries(
      Object.entries(props.options.formatTable.formats || {}).map(([name, formatData]) => {
        return [name, formatFactory({ ...formatData, lang, postFormat: props.context.postFormat })]
      }),
    )
    if (mode.value !== 'pivot') {
      const rowName = columns.value.includes('name') ? 'name' : columns[0]
      return (v: number, col: string, row: Record<string, any>) => {
        return formats[row[rowName]] ? formats[row[rowName]](v) : v
      }
    } else {
      const rowName = props.data.labels[0]
      return (v: number, col: string, row: Record<string, any>) => {
        return formats[row[rowName]] ? formats[row[rowName]](v) : v
      }
    }
  }
  return (v: number, col: string, row?: any[]): string | number => v
})
</script>
<script lang="ts">
function getTableMode(data) {
  if (!data) return
  if (data.columns) {
    if (data.labels) return 'pivot'
    return 'table'
  }
  return Array.isArray(data[0]) ? 'array' : 'object'
}
function getColumns({ data_component, options }: BuilderOptionArgs) {
  if (!data_component) return []
  const mode = getTableMode(data_component)
  if (mode === 'pivot') {
    const columns = data_component.columns.map(d => d.name || d.field || d)
    return options.display === 'compact' ? ['label'].concat(columns) : data_component.labels.concat(columns)
  }
  if (!data_component.length) return []
  return mode === 'array' ? data_component[0] : Object.keys(data_component[0])
}
const api: BuilderOptions = {
  tableName: {
    label: 'Table Name',
    default: () => null,
    type: 'translationInput',
  },
  columns: {
    label: 'Columns',
    multiSelect: {
      values: getColumns,
    },
    default: getColumns,
  },
  showSubtotals: {
    label: 'Show Subtotals',
    default: () => true,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
    hide: ({ data_component }) => getTableMode(data_component) !== 'pivot',
  },
  showTotal: {
    label: 'Show Total',
    default: () => false,
    attrs: {
      type: 'checkbox',
      class: 'none',
    },
  },
  display: {
    label: 'Display',
    default: () => 'outline',
    select: () => ['compact', 'outline'],
    reset: ['columns'],
    hide: ({ data_component }) => getTableMode(data_component) !== 'pivot',
  },
  striping: {
    label: 'Striping',
    default: () => 'odd',
    select: ({ data_component }) => {
      const mode = getTableMode(data_component)
      if (mode === 'pivot') {
        return ['', 'odd', 'even', ...data_component.labels]
      }
      return ['', 'odd', 'even']
    },
  },
  formatTable: {
    label: 'Format',
    modes: [
      {
        label: 'Format Table',
        mode: 'table',
        options: {
          unit: {
            label: 'Unit',
            default: () => '%',
          },
          digit: {
            label: 'Decimals',
            default: () => 2,
            attrs: {
              type: 'number',
            },
          },
        },
      },
      {
        label: 'Format Columns',
        mode: 'columns',
        options: {
          formats: {
            label: 'Column formats',
            multiple: {
              values: getColumns,
              options: {
                unit: {
                  label: 'Unit',
                  default: () => '%',
                },
                digit: {
                  label: 'Decimals',
                  default: () => 2,
                  attrs: {
                    type: 'number',
                  },
                },
              },
            },
          },
        },
      },
      {
        label: 'Format Rows',
        mode: 'rows',
        options: {
          formats: {
            label: 'Row formats',
            multiple: {
              values: ({ data_component }) => {
                // NOTE: problem with this is the same logic needs to be implemented in the components that use formatTable
                const mode = getTableMode(data_component)
                if (mode === 'pivot') {
                  return Array.from(new Set(data_component.rows.map(d => d[0])))
                }
                const columns = Object.keys(data_component[0] || {})
                const rowName = columns.includes('name') ? 'name' : columns[0]
                return data_component.map(d => d[rowName])
              },
              options: {
                unit: {
                  label: 'Unit',
                  default: () => '%',
                },
                digit: {
                  label: 'Decimals',
                  default: () => 2,
                  attrs: {
                    type: 'number',
                  },
                },
              },
            },
          },
        },
      },
    ],
  },
}

export default {
  api,
  styles: {
    'table-header': `font-weight: bold;
.table-header-cell:first-of-type {
  text-align: left;
}`,
    'table-row': `.table-cell:first-of-type {
  text-align: left;
}`,
    'table-cell': `padding: 4px;
text-align: center;`,
    'table-header-cell': `padding: 4px;
text-align: center;`,
    'table-row.striped': {
      name: 'Striping',
      css: 'background-color: #e2e8f0;',
    },
    'all-table': {
      name: 'Table',
      css: `.level-0 {
 font-weight: bold;
}
.column-0 {
 text-align: left;
}`,
    },
  },
  story: table,
}
</script>
<style scoped>
.builder-list td:first-child {
  width: auto; /* Ensures first column width fits content */
  /* min-width: 100px; */
}
th {
  font-weight: unset;
}
</style>
