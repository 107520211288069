<template>
  <h1>{{ title }}</h1>
  <subtitle style="font-style: italic; margin-top: -5px">
    {{ isNew ? t.data_report_results.subtitle.new : t.data_report_results.subtitle.edit }}
  </subtitle>
  <loader v-if="loading" />
  <div v-else-if="dataReportDefinition" class="expand container block">
    <div class="form">
      <div class="column">
        <span class="data-report-details">
          {{ dataReportDefinition.name }} -
          {{ dataReportDefinition.specialtyName }}
        </span>
      </div>
      <div :class="`column check-variables`" style="margin-top: 12px">
        <label for="variables">{{ $root.t.data_report_results.variables }}</label>
        <div class="row run-variables">
          <dr-variable-input
            v-for="param in variableSettingInputs"
            :variableDefinition="param"
            :inputData="inputData?.[param.name]"
            :missingDependencies="missingDependencies[param.name]"
            :value="variables[param.name]"
            :key="param.name"
            @update:value="value => updateVariable(param, value)"
          />
        </div>
      </div>
      <div class="column" style="margin-top: 36px">
        <label for="file">{{ $root.t.data_report_results.file }}</label>
        <div class="row upload-file">
          <button class="primary upload-button" style="margin-right: 8px" @click="triggerFileInput">
            <ui-asset :width="20" :height="20" name="icon_download"></ui-asset>
            {{ $root.t.data_report_results.upload }}
          </button>
          <input class="hidden" type="file" ref="fileInput" accept="application/json" @change="importFile" />
          <span>{{ form.filename || $root.t.data_report_results.no_uploaded_file }}</span>
        </div>
      </div>
      <div class="column" style="margin-top: 36px">
        <label for="json">{{ $root.t.data_report_results.data_preview }}</label>
        <code-editor
          language="json"
          maxHeight="250px"
          class="preview-editor"
          :code="form.result || $root.t.data_report_results.upload_file_to_preview"
          @update="e => (form.result = e)"
        />
      </div>
    </div>
    <div class="footer">
      <span>
        <button class="primary" style="margin-right: 8px" @click="save">{{ $root.t.save }}</button>
        <button class="secondary" @click="cancel">{{ $root.t.cancel }}</button>
      </span>
    </div>
  </div>
</template>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;
  padding: 24px;
}
.data-report-details {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px;
}
.form {
  display: flex;
  flex-direction: column;
}
button {
  width: fit-content;
}
.run-variables {
  margin-top: 8px;
  margin-left: 10px;
}
.check-variables > div {
  gap: 10px;
  align-items: center;
  margin-bottom: 6px;
}

.upload-file {
  align-items: center;
  margin-top: 8px;
  gap: 12px;
}
.upload-button {
  display: flex;
  align-items: center;
  gap: 8px;
}
.preview-editor {
  margin-top: 8px;
}
.footer {
  padding-top: 12px;
}
</style>

<script setup lang="ts">
import { computed, onMounted, ref, watchEffect } from 'vue'
import dataReportResultService, { DataReportResult } from '@100-m/hauru/src/services/DataReportResultService'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import { readJSONFile } from '../../builder/lib/assetManagement'
import { useDrVariables, VariableDefinitions } from '@100-m/hauru/src/components/form/drVariables/lib'
import DrVariableInput from '@100-m/hauru/src/components/form/drVariables/drVariableInput.vue'

const { variables, updateVariable, partials, inputData, missingDependencies, initDrVariables, runParameters } =
  useDrVariables()

const variableSettingInputs = computed(() => partials.value.concat(runParameters.value))

// ⚠️ Store this variable as hauru overrides route and removes id param
const isNew: boolean = !$root.$route.query.id

const loading = ref<boolean>(false)
const dataReportDefinition = ref<{
  id: number
  name: string
  specialtyName: string
  settingVariableParameters: VariableDefinitions
} | null>(null)
const dataReportResult = ref<DataReportResult | null>(null)
const fileInput = ref<HTMLButtonElement | null>(null)

const runVariablesState = ref<{ type: 'none' | 'valid' | 'invalid'; message?: string }>({
  type: 'none',
  message: undefined,
})

const form = ref<{ filename?: string; result?: string }>({
  filename: undefined,
  result: undefined,
})

const title = computed<string>(() => {
  if (isNew) {
    return $root.t.data_report_results.create_data_report_result
  }

  return $root.t.data_report_results.update_data_report_result
})

onMounted(async () => {
  loading.value = true

  const dataReportDefinitionId =
    typeof $root.$route.query.dataReportDefinitionId === 'string' && parseInt($root.$route.query.dataReportDefinitionId)
  const dataReportResultId = typeof $root.$route.query.id === 'string' && parseInt($root.$route.query.id)

  if (dataReportDefinitionId) {
    dataReportDefinition.value = await dataReportService.getById(dataReportDefinitionId)
  }

  if (dataReportResultId) {
    dataReportResult.value = await dataReportResultService.getById({
      id: dataReportResultId,
    })
  }

  loading.value = false

  if (isNew && dataReportDefinition.value?.settingVariableParameters) {
    await initDrVariables(dataReportDefinition.value?.settingVariableParameters)
  } else if (dataReportDefinition.value?.settingVariableParameters && dataReportResult.value) {
    await initDrVariables(dataReportDefinition.value?.settingVariableParameters, dataReportResult.value.runVariables)
  }
})

watchEffect(() => {
  if (dataReportResult.value) {
    form.value.filename = dataReportResult.value.metaData?.filename as string
    form.value.result = JSON.stringify(dataReportResult.value.result, null, 2)
  }
})

function triggerFileInput() {
  fileInput.value?.click()
}

async function save() {
  if (!checkVariables()) {
    return
  }

  if (!form.value.filename) {
    return $root.toast({ description: $root.t.data_report_results.no_uploaded_file, type: 'error', timeout: 5000 })
  }

  const runVariables = await variables

  const existingDataReportResult = await dataReportResultService.findFirst({
    dataReportDefinitionId: dataReportDefinition.value?.id || NaN,
    runVariables,
  })

  if (existingDataReportResult && existingDataReportResult.id !== dataReportResult.value?.id) {
    return $root.toast({
      description: $root.t.data_report_results.already_existing_data_report_result,
      type: 'error',
      timeout: 5000,
    })
  }

  let result = {}
  try {
    result = JSON.parse(form.value.result || '{}')
  } catch (e) {
    return $root.toast({
      description: $root.t.data_report_run.error_json_malformed,
      type: 'error',
      timeout: 5000,
    })
  }

  if (isNew) {
    await dataReportResultService.create({
      dataReportResultInput: {
        dataReportDefinitionId: dataReportDefinition.value?.id,
        runVariables,
        result,
        metaData: { origin: 'file', filename: form.value.filename },
      },
    })
  } else {
    await dataReportResultService.update({
      id: dataReportResult.value?.id || NaN,
      dataReportResultInput: {
        runVariables,
        result,
        metaData: { origin: 'file', filename: form.value.filename },
      },
    })
  }

  $root.toast({
    description: isNew
      ? $root.t.data_report_results.create_data_report_result_success
      : $root.t.data_report_results.update_data_report_result_success,
    type: 'success',
    timeout: 5000,
  })

  $root.$router.push({
    path: $root.appath + 'data-report',
    query: { id: dataReportDefinition.value?.id, tab: 'results' },
  })
}

// ⚠️ Doesn't go back to prevent issues with hauru router overrides
function cancel(): void {
  $root.$router.push({
    path: $root.appath + 'data-report',
    query: { id: dataReportDefinition.value?.id, tab: 'results' },
  })
}

function checkVariables() {
  if (!dataReportDefinition.value?.settingVariableParameters) {
    return $root.toast({ description: $root.t.data_report_results.no_specialty_settings, type: 'error', timeout: 5000 })
  }

  const missingVariables = variableSettingInputs.value
    .map(param => {
      if (!variables[param.name]) {
        return param.name
      }
    })
    .filter(e => e)

  if (missingVariables.length) {
    $root.toast({
      description: `${$root.t.data_report_results.missing_variables} : ${missingVariables.join(', ')}`,
      type: 'error',
      timeout: 5000,
    })

    return false
  }
  return true
}

async function importFile(event: Event) {
  const file = await readJSONFile(event)

  if (!file) {
    $root.toast({ description: $root.t.data_report_result_format_error, type: 'error', timeout: 5000 })
  }

  form.value.filename = (event.target as HTMLInputElement).files?.[0]?.name || ''
  form.value.result = JSON.stringify(file, null, 2)
}
</script>
