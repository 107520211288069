// TODO remove d3 dependency ?
import { get, set, update } from '../features/commandr.js'
import * as d3 from 'd3'

function isTestEnv() {
  const isNode = Object.prototype.toString.call(typeof process !== 'undefined' ? process : 0) === '[object process]'
  return isNode && process?.env?.NODE_ENV === 'test'
}

export default (app, { config }) => {
  const globalProperties = app.config.globalProperties
  window.d3 = d3
  globalProperties.window = window
  globalProperties.document = document
  globalProperties.localStorage = localStorage
  globalProperties.sessionStorage = sessionStorage
  window.µ = globalProperties.µ = (selector, node = document) => node.querySelector(selector)
  window.µµ = globalProperties.µµ = (selector, node = document) => Array.from(node.querySelectorAll(selector))
  window.browser = (ua = navigator.userAgent) => {
    if (/MSIE/.test(ua) || /Trident/.test(ua)) return 'ie'
    if (/Edge/.test(ua)) return 'edge'
    if (/Firefox/.test(ua)) return 'firefox'
    if (/Opera/.test(ua)) return 'opera'
    if (/Chrome/.test(ua)) return 'chrome'
    if (/Safari/.test(ua)) return 'safari'
    return 'unknown'
  }

  // TODO REMOVE
  window.update_domain = globalProperties.update_domain = domain => {
    if (!domain || !domain.length) return window.update_query({ domain: null })
    if (domain.length === 1) domain = [domain[0], domain[0]]
    const d = domain.__.map(d => d && new Date(d).format())
    if ($root.xs && $root.screen && $root.screen.datasets && $root.xs[$root.screen.datasets[0]][0]) {
      const dates = $root.xs[$root.screen.datasets[0]][0].dates.__.keys()
      d[0] = dates.__.find(k => k >= d[0]) || dates.last()
      d[1] = dates.__.find(k => k >= d[1]) || dates.last()
    }
    return window.update_query({ domain: d.unique().sort().join('|') })
  }
  window.log = globalProperties.log = console.log.bind(console)
  window.dbg = globalProperties.dbg = function () {
    console.log(arguments)
    // debugger // eslint-disable-line
  }
  window.resize = globalProperties.resize = () => window.dispatchEvent(new Event('resize'))
  window.size = () => {
    let size = ''
    if (window.innerWidth > 1500) size += 'desktop4K '
    if (window.innerWidth > 1200) size += 'desktop '
    if (window.innerWidth > 900) size += 'laptop '
    if (window.innerWidth > 600) size += 'tablet '
    return (size += 'mobile')
  }
  function format(fmt) {
    // return d3.format(fmt)
    return x => {
      const formats = Object.assign({}, $root.config.formats || {}, $root.screen.formats || {})
      if (['fr', 'es', 'it'].includes($root.lang))
        d3.formatDefaultLocale({ decimal: ',', thousands: ' ', grouping: [3] })
      else d3.formatDefaultLocale({ decimal: '.', thousands: ',', grouping: [3] })
      if (!fmt) return x
      if (fmt === 'recovery' && typeof x === 'string') {
        return $root.t[x] || x
      }
      if (typeof x === 'string') {
        // HACK to match the behavior of the Date override of the old platform ('0,03%' is a valid date, '0,03%T12:00' is invalid)
        if (new Date(`${x}${x.length <= 10 ? 'T12:00' : ''}`) == 'Invalid Date') return x
        if (!formats[fmt]) return x
        return new Date(x).format(formats[fmt])
      }
      if (typeof fmt === 'function') return fmt(x)
      if (typeof fmt !== 'string') return x
      if (/\{([^\}]*)\}/.test(fmt)) return fmt.replace(/\{([^\}]*)\}/g, (m, a) => format(a)(x))
      if (x === 0 && formats.zero === true) fmt = fmt.replace(/\.[0-9]*/, '.0')
      if (x === null || x === undefined) return ''
      // if (x === 0) return x
      if (fmt === 'M') return d3.format(',')(Math.round((x * 100) / 1000000) / 100)
      if (fmt === '.2fM€') return format('.2f')(x / 1000000) + 'M€'
      if (fmt === 'M€') return format('M')(x) + 'M€'
      if (/^M(EUR|USD|GBP|YEN|CHF)$/.test(fmt))
        return format('M')(x) + fmt.replace('USD', '$').replace('EUR', '€').replace('YEN', '¥').replace('GBP', '£')
      if (fmt === 'bp') return Math.round(x * 10000) + 'bp'
      if (fmt === 'bp_blackstone') return d3.format(',')(Math.round(x)) + 'bp'
      if (fmt === '+bp') return x > 0 ? '+' + Math.round(x * 10000) + 'bp' : Math.round(x * 10000) + 'bp'
      if (fmt === 'd')
        return (
          parseInt(x / (24 * 60 * 60000)) +
          'd' +
          parseInt((x / 60000 - parseInt(x / (24 * 60 * 60000)) * 24 * 60) / 60) +
          'h'
        )
      if (fmt === 'pt') return d3.format('.3')(x * 100) + ' '
      if (fmt === 'aum')
        return new Intl.NumberFormat($root.lang.slice(0, 2), {
          style: 'currency',
          currency: formats[fmt].includes('€')
            ? 'EUR'
            : formats[fmt].includes('$')
              ? 'USD'
              : formats[fmt].includes('£')
                ? 'GBP'
                : formats[fmt].includes('CHF')
                  ? 'CHF'
                  : 'EUR',
          notation: 'compact',
          compactDisplay: 'short',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
          .format(x)
          .replace(/\s/g, '')
      if (formats[fmt]) return format(formats[fmt])(x)
      const matchfmt = formats.__.findIndex((v, f) => RegExp(f).test(fmt))
      if (matchfmt) return format(formats[matchfmt])(x)

      try {
        let [match, f, currency = ''] = fmt
          .replace('USD', '$')
          .replace('EUR', '€')
          .replace('YEN', '¥')
          .replace('GBP', '£')
          .match(/([^$€£¥]*)([$€£¥])?/)
        if (f.slice(-3) === 'CHF') {
          f = f.replace('CHF', '')
          currency = 'CHF'
        }
        if (/s$/.test(f) && Math.abs(x) <= 1) f = f.replace('s', 'f')
        if (f.slice(-2) === 'fs') {
          const fixedPrecision = parseInt(f[1]) + 1
          return (
            new Intl.NumberFormat($root.lang.slice(0, 2), {
              notation: 'compact',
              compactDisplay: 'short',
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })
              .format(x)
              .replace(/\s/g, '') + currency
          )
        }
        return (
          d3
            .format(f)(x)
            .replace(/^NaNk$/, 0)
            .replace('NaN', 0)
            .replace('G', $root.lang === 'fr' ? 'Md' : 'Bn') + currency
        )
      } catch (e) {
        return x
      }
    }
  }

  window.format = format
  if (!isTestEnv()) {
    globalProperties.format = format
  }

  window.unit = globalProperties.unit = str => {
    const matches = ('' + str).replace('−', '-').match(/^([\s\d.+-]+)(.*)$/)
    if (matches) return '<span class="number">{0}</span><span class="unit">{1}</span>'.format(matches.slice(1))
    return str
  }

  window.uquery = globalProperties.uquery = query =>
    $root.$router.push({ query: Object.assign({}, $root.$route.query, query).__.filter(v => v || v === 0) })
  window.ufilter = globalProperties.ufilter = (k, v) => {
    const query = JSON.parse(JSON.stringify($root.$route.query || {}))
    query[k] = query[k] ? query[k].split('|') : []
    if (!v) return
    v.split('|').__.map(v => (query[k] = query[k].toggle(v)))
    query[k] = query[k].join('|')
    return uquery(query)
  }
  window.usearch = globalProperties.usearch = search => {
    const query = Object.fromEntries(
      (location.href.split('?')[1] || '')
        .split('&')
        .__.filter(d => d)
        .__.map(d => d.split('=')),
    ).__.map(decodeURIComponent)
    if (search === query.search) search = ''
    if (!search) delete query.search
    else query.search = search
    history.replaceState(
      {},
      null,
      location.href.split('?')[0] + query.__.reduce((acc, v, k) => `${acc}&${k}=${v}`, '?').replace('?&', '?'),
    )
    dispatchEvent(new CustomEvent('search', { detail: search }))
  }
  window.update_query = globalProperties.update_query = uquery.throttle(400)
  window.update_filter = globalProperties.update_filter = ufilter.throttle(400)
  window.update_search = globalProperties.update_search = usearch.throttle(400)
  window.type = globalProperties.type = x => Object.prototype.toString.call(x).slice(8, -1).toLowerCase()

  // TODO: HACK add comment on why this hack and possibly what the ideal solution should be.
  window.top_bottom = (ds, len) => {
    const positive = ds.__.filter(d => d[1] >= 0).sort('-1')
    const negative = ds.__.filter(d => d[1] < 0).sort('1')
    return [['positive_key', 'positive_value', 'negative_key', 'negative_value']].concat(
      Array(len)
        .fill()
        .__.map((d, i) =>
          [positive[i] || ['', ''], negative[i] || ['', '']].__.filter(d => d)
            .__.map(d => [d[0], d[1]])
            .flat(Infinity),
        ),
    )
  }

  // TODO consider moving analytics logic to a dedicated module
  window._analytics = key => {
    const [fn, period, metric, arg1, arg2] = key.split('.')
    if (metric === 'diff')
      return analytics(key.replace('diff', 'fund')) - analytics(key.replace('diff', 'benchmark')) || '-'
    if (!$root.dates || !$root.dates.length || !$root.domain[1]) return '-'
    const domain = (period => {
      const first_date = new Date($root.dates.first())
      const last_date = new Date($root.domain[1])
      if (['1m', '2m', '3m', '4m', '5m', '6m', '7m', '8m', '9m', '10m', '11m', '12m'].includes(period))
        return [last_date.minus(`${period.slice(0, -1)} month`).end('month'), last_date]
      if (
        [
          '1y',
          '2y',
          '3y',
          '4y',
          '5y',
          '6y',
          '7y',
          '8y',
          '9y',
          '10y',
          '11y',
          '12y',
          '13y',
          '14y',
          '15y',
          '16y',
          '17y',
          '18y',
          '19y',
          '20y',
        ].includes(period)
      )
        return [last_date.minus(`${period.slice(0, -1)} year`).end('month'), last_date]
      if (period === 'mtd') return [last_date.minus('1 month').end('month'), last_date]
      if (period === 'ytd') return [last_date.minus('1 year').end('year'), last_date]
      if (period === 'inception') return [first_date, last_date]
      if (period.startsWith('years-'))
        return [
          new Date((last_date.getFullYear() - period.split('-')[1]).toString()).minus('year').end('year'),
          new Date((last_date.getFullYear() - period.split('-')[1] + 1).toString()).minus('year').end('year'),
        ]
      if (period === 'domain') return [new Date($root.domain[0]), last_date]
    })(period)

    const dates = $root.x.performance.dates
    if (new Date(domain[0]) < new Date(dates.keys().first())) return '-'
    const d0 = new Date(
      dates
        .keys()
        .sort()
        .filter(d => d <= domain[0].format())
        .last(),
    ).format()
    const d1 = domain[1].format()
    const before_d0 =
      dates[
        dates
          .keys()
          .reverse()
          .find(k => k <= d0)
      ]
    const after_d1 = dates[dates.keys().find(k => k > d1)]
    // For some indicators, I need before before_d0 to compute returns on 1y / 3y / 5y
    const x = ['var_X'].includes(fn) ? 1 : 0
    const data = $root.x.performance.slice(before_d0 - x, after_d1) // minus 1 day
    if (
      ['performance', 'performance_annualized', 'performance_annualized_non_annual', 'max_drawdown_value'].includes(fn)
    )
      return data[fn](metric)
    // NEED ONE WEEK PRIOR
    const data_minus_1week = $root.x.performance.filter(
      d => d.date > new Date(d0).minus('7 days').format() && d.date <= $root.domain[1],
    )
    if (['volatility'].includes(fn)) return data_minus_1week[fn](metric)
    if (['tracking_error', 'information_ratio', 'sharpe_ratio', 'alpha', 'beta'].includes(fn)) {
      if (metric) return data_minus_1week[fn](metric)
      return data_minus_1week[fn]()
    }
    if (['var_X'].includes(fn)) return data[fn](metric, arg1)
    if (['var_X_Y_days'].includes(fn)) return data[fn](metric, arg1, arg2)
  }
  // window.__analytics = _analytics.memoize()
  // TODO docunent analytics interpretation
  window.analytics = globalProperties.analytics = _analytics
  window.unit = globalProperties.unit = str => {
    const matches = ('' + str).replace('−', '-').match(/^([\s\d.+-]+)(.*)$/)
    if (matches) return '<span class="number">{0}</span><span class="unit">{1}</span>'.format(matches.slice(1))
    return str
  }
  window.format_asof = asof => {
    if (/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(asof)) return new Date(asof).format('YYYYMMDD')
    if (/[0-9]{4}-[0-9]{2}/.test(asof)) return new Date(asof).end('month').format('YYYYMMDD')
    if (/[0-9]{4}/.test(asof) && asof.length === 4) return new Date(asof).end('year').format('YYYYMMDD')
    return new Date().format('YYYYMMDD')
  }

  window.inject = async x => {
    if (!x || !x.length || µ(x.includes('.css') ? `[href="${x}"]` : `[src="${x}"]`)) return
    if (Array.isArray(x))
      return Promise.all(x.__.filter(url => !Array.isArray(url)).__.map(inject)).then(() =>
        inject(x.__.filter(url => Array.isArray(url)).flat(1)),
      )
    return new Promise((resolve, reject) => {
      const el = document.createElement(x.includes('.css') ? 'link' : 'script')
      if (x.includes('.css')) el.rel = 'stylesheet'
      el[x.includes('.css') ? 'href' : 'src'] = x
      el.onload = resolve
      el.onerror = reject
      document.head.appendChild(el)
    })
  }
  window.idb = {
    db: new Promise((resolve, reject) => {
      const openreq = indexedDB.open('kvs', 1)
      openreq.onerror = () => reject(openreq.error)
      openreq.onsuccess = () => resolve(openreq.result)
      openreq.onupgradeneeded = () => openreq.result.createObjectStore('kv')
    }).then(db => {
      db.onversionchange = () => db.close()
      return db
    }),
    transaction(type, fn) {
      return this.db
        .then(
          db =>
            new Promise((resolve, reject) => {
              const transaction = db.transaction('kv', type)
              transaction.oncomplete = () => resolve(request)
              transaction.onabort = transaction.onerror = () => reject(transaction.error)
              const request = fn(transaction.objectStore('kv'))
            }),
        )
        .then(req => req.result)
    },
    get(key) {
      return this.transaction('readonly', store => store.get(key))
    },
    set(key, value) {
      return this.transaction('readwrite', store => store.put(value, key))
    },
    del(key) {
      return this.transaction('readwrite', store => store.delete(key))
    },
    clear() {
      return this.transaction('readwrite', store => store.clear())
    },
    keys() {
      // store.getAllKeys()
      const keys = []
      return this.transaction(
        'readonly',
        store =>
          ((store.openKeyCursor || store.openCursor).call(store).onsuccess = function () {
            if (!this.result) return
            keys.push(this.result.key)
            this.result.continue()
          }),
      ).then(() => keys)
    },
  }

  window.get = globalProperties.get = get
  window.set = globalProperties.set = set
  window.update = globalProperties.update = update
}
