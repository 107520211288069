import { STSClient, AssumeRoleCommand } from '@aws-sdk/client-sts'
import {
  S3Client,
  PutObjectCommand,
  GetObjectCommand,
  DeleteObjectCommand,
  DeleteObjectsCommand,
  ListObjectsV2Command
} from '@aws-sdk/client-s3'
import { findContentType } from '../utils/findContentType.js'

export default class AWSFileSystem {
  #awsAssumeRole
  #stsClient
  #bucketName
  #awsRegion

  constructor(config) {
    this.#bucketName = config.awsBucketName
    this.#awsRegion = config.awsRegion

    this.#awsAssumeRole = new AssumeRoleCommand(config.awsAssumeRole)
    this.#stsClient = new STSClient({
      region: this.#awsRegion,
      credentials: config.awsCredentials
    })
  }

  async setupAwsClient() {
    const assumedRole = await this.#stsClient.send(this.#awsAssumeRole)
    return new S3Client({
      region: this.#awsRegion, // eu-central-1
      credentials: {
        accessKeyId: assumedRole.Credentials.AccessKeyId,
        secretAccessKey: assumedRole.Credentials.SecretAccessKey,
        sessionToken: assumedRole.Credentials.SessionToken
      }
    })
  }

  async upload({ buffer, filenameWithPath, isPrivateFile }) {
    const awsClient = await this.setupAwsClient()

    const uploadParams = {
      Bucket: this.#bucketName,
      Key: `${isPrivateFile ? '' : 'public/'}${filenameWithPath}`,
      Body: buffer,
      ContentType: findContentType(filenameWithPath)
    }
    try {
      const data = await awsClient.send(new PutObjectCommand(uploadParams))
      console.log('Success', data)
    } catch (err) {
      console.error('Error', err)
    }
  }

  async get({ filenameWithPath, isPrivateFile }) {
    const awsClient = await this.setupAwsClient()

    try {
      const downloadParams = {
        Bucket: this.#bucketName,
        Key: `${isPrivateFile ? '' : 'public/'}${filenameWithPath}`
      }

      const data = await awsClient.send(new GetObjectCommand(downloadParams))

      const uint8Array = await data.Body.transformToByteArray()

      return new Blob([uint8Array])
    } catch (err) {
      console.error('Error', err)
    }
  }

  getPublicUrl({ filenameWithPath }) {
    return `https://${this.#bucketName}.s3.${this.#awsRegion}.amazonaws.com/public/${filenameWithPath}`
  }

  async deleteFile({ filenameWithPath, isPrivateFile }) {
    const awsClient = await this.setupAwsClient()

    try {
      const deleteParams = {
        Bucket: this.#bucketName,
        Key: `${isPrivateFile ? '' : 'public/'}${filenameWithPath}`
      }

      const data = await awsClient.send(new DeleteObjectCommand(deleteParams))
      console.log('Success', data)
    } catch (err) {
      console.error('Error', err)
    }
  }

  async deleteFolder({ filenameWithPath, isPrivateFolder }) {
    const awsClient = await this.setupAwsClient()

    try {
      const listParams = {
        Bucket: this.#bucketName,
        Prefix: `${isPrivateFolder ? '' : 'public/'}${filenameWithPath}`
      }

      const data = await awsClient.send(new ListObjectsV2Command(listParams))

      const deleteParams = {
        Bucket: this.#bucketName,
        Delete: {
          Objects: data.Contents.map(({ Key }) => ({ Key }))
        }
      }

      await awsClient.send(new DeleteObjectsCommand(deleteParams))
    } catch (err) {
      console.error('Error', err)
    }
  }
}
