import config from '../../../config'
import { useGraphQL } from '../../../utils/axios'

export function useDates() {
  async function _getDates(params, queryName = 'performanceDates') {
    const { sendQuery } = useGraphQL(config)
    const { isinShare, externalOptions } = params
    const query = `#graphql
      query shareInfo(
        $fuid: FuidInput!
        $externalOptions: JSON
      ) {
        referential {
          share(fuid: $fuid, externalOptions: $externalOptions) {
            ${queryName}
          }
        }
      }`
    const variables = {
      fuid: isinShare,
      externalOptions,
    }
    const resp = await sendQuery(query, variables)
    return resp.referential.share[queryName]
  }
  const getPerformanceDates = async params => {
    return _getDates(params, 'performanceDates')
  }
  const getAllocationDates = async params => {
    return _getDates(params, 'allocationDates')
  }
  const getContributionDates = async params => {
    return _getDates(params, 'contributionDates')
  }

  return {
    getPerformanceDates,
    getAllocationDates,
    getContributionDates,
  }
}
