import { RestService } from './RestService'

type Template = {
  id: number
  name: string
  layout: { [key: string]: unknown }
  type: string
  dataReportId: number | null
  dataQualityCheckReportId: number
  createdAt: string | null
}

export class TemplateService extends RestService {
  baseRoute: string

  constructor() {
    super()
    this.baseRoute = 'template'
  }

  async findMany(): Promise<Template[]> {
    return this.get(`${this.baseRoute}`)
  }

  async findManyByName({ name, limit = 1 }: { name: string; limit?: number }): Promise<Template[]> {
    return this.get(`${this.baseRoute}/by-name`, {
      params: { name, limit },
    })
  }

  async create({ templateInput }: { templateInput: any }): Promise<Template> {
    return this.post(`${this.baseRoute}/create`, {
      templateInput,
    })
  }

  async deleteOne({ id }: { id: number }): Promise<Template> {
    return this.delete(`${this.baseRoute}/${id}`)
  }

  async deleteManyByName({ name, limit = 1 }: { name: string; limit?: number }): Promise<Template[]> {
    return this.delete(`${this.baseRoute}`, {
      params: { name, limit },
    })
  }
}

const templateService = new TemplateService()
export default templateService
