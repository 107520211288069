/* globals window, $root, config */
import { GraphQlService } from './GraphQlService'

export type Specialty = {
  name: string
  settings: any
}

export class SpecialtyService extends GraphQlService {
  async list() {
    return (
      await this.query(
        `
        query Specialties {
          specialties {
            getSpecialties {
              name
              settings
            }
          }
        }`,
      )
    ).data.specialties.getSpecialties
  }

  async getByName(name) {
    return (
      await this.query(
        `
        query GetSpecialtyByName($name: String!) {
          specialties {
            getSpecialtyByName(name: $name) {
              name
              settings
            }
          }
        }`,
        { name },
      )
    ).data.specialties.getSpecialtyByName
  }
}

const specialtyService = new SpecialtyService()
export default specialtyService
