import { computed } from 'vue'
import { BuilderProps } from './builderComponent'

export default function usePagination(props: BuilderProps) {
  const pageNumber = computed<number>(() => {
    let basePage = props.context.path[0]
    // Array.from({ length: basePage }).forEach((_, i) => {
    //   if (props.context.layout.overflowPages[i]) basePage += 1
    // })
    // if (props.context.virtual) basePage += 1
    return basePage + 1
  })
  const pageCount = computed<number>(
    () => (props.context?.layout?.nodes?.length || 1) + Object.keys(props.context?.layout?.overflowPages || {}).length,
  )
  return { pageNumber, pageCount }
}
